<template>
  <v-dialog persistent max-width="500" v-model="dialog" class="elevation-0 ">
    <v-btn style="position:absolute;z-index: 1;" icon dark @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <template v-if="online == 1">
      <router-link class="elevation-0" :to="`/live`">
        <v-img class="elevation-0" :src="img"> </v-img>
      </router-link>
    </template>
    <template v-else>
      <v-img class="elevation-0" :src="img"> </v-img>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: ''
    },
    online: {
      type: Number,
      defaulr: 0
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false
    }
  },
  methods: {
    close () {
      this.$emit('closeDialogLive', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
