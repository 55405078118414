<template>
  <v-sheet class="niu-width-track  mb-1" light>
    <v-row>
      <v-col cols="12" md="3">
        <div style="border-right: 1px solid #c3c3c3;height: 90%;">
          <div :id="infoTrack.trackId" class="text-track-title">
            <label
              :style="
                `color:${$vuetify.theme.defaults.light.primary}; font-weight:bold`
              "
              >{{ `${infoTrack.trackTitle} ` }}
            </label>
            <v-avatar :size="sizeLogoTrack">
              <v-img
                :size="sizeLogoTrack"
                :src="
                  infoTrack.isPremium === 0
                    ? require('../../assets/weblogo_n.png')
                    : require('../../assets/logo_premium_150x150.png')
                "
              >
              </v-img>
            </v-avatar>
            <v-avatar
              :size="sizeLogoTrack"
              v-if="infoTrack.trackMobileFriendly === 1"
              color="primary"
            >
              <v-img
                :size="sizeLogoTrack"
                :src="require('../../assets/iconos/moviles.png')"
              >
              </v-img>
            </v-avatar>
            <span v-if="infoTrack.isPersonalizadoNiufleex == 1">
              Personalizado
            </span>
            <p>
              <span
                :style="
                  `font-size:14px;font-weight:bold;color:${$vuetify.theme.defaults.light.primary}`
                "
              >
                Curso
              </span>
              <span :style="`color:${$vuetify.theme.defaults.light.secondary}`">
                |
              </span>
              <span
                :style="
                  `font-size:14px;font-weight:lighter;color:${$vuetify.theme.defaults.light.primary}`
                "
              >
                Modalidad Track
              </span>
              <v-btn
                @click="ver_mas_informacion(infoTrack)"
                :color="$vuetify.theme.defaults.light.secondary"
                style="color:#FFF;font-size:12px;text-transform:capitalize;"
                class="pa-1 ma-0"
              >
                <v-icon size="18">mdi-play-circle-outline</v-icon>
                <span
                  v-if="
                    $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm'
                  "
                  >Ver más información</span
                >
                <v-icon size="18" v-else>mdi-plus</v-icon>
                <!-- {{$vuetify.breakpoint.name}} -->
              </v-btn>
            </p>
            <div>
              <v-avatar class="ma-2" v-if="especialista.img">
                <v-img
                  :src="
                    especialista.img ||
                      require('../../assets/especialistas/blanco.jpg')
                  "
                >
                </v-img>
              </v-avatar>
              <label
                :style="
                  `color:${$vuetify.theme.defaults.light.primary}; font-size: 14px;`
                "
                >{{ especialista.nickname }}</label
              >
            </div>
            <!-- <div class="niu-iconos">
              <label>
                <v-icon style="color: #717171;">mdi-ballot-outline</v-icon>
                Módulos:
                {{ modulos.length }}
              </label>
              <label v-if="trackSesiones != 0">
                <v-icon style="color: #717171;">mdi-file-document</v-icon>
                Sesiones: {{ trackSesiones || '00' }}
              </label>
              <label v-if="trackDuracion != 0">
                <v-icon style="color: #717171;"
                  >mdi-clock-time-three-outline</v-icon
                >
                Duración: {{ trackDuracion || '000' }} min</label
              >
            </div>
            <div class="niu-iconos">
              <label>
                <v-icon style="color: #717171;">mdi-tag</v-icon>
                Área de Conocimiento:
                {{
                  KnowledgeAreas2.find(
                    ele => ele.name == infoTrack.trackAreaconocimientoCode
                  ).descripcion
                }}
              </label>
            </div> -->
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="9">
        <v-slide-group v-model="model" active-class="success" show-arrows>
          <v-slide-item
            v-for="(modulo, index) in modulos"
            :key="
              `modulo_${$router.currentRoute.name}_${infoTrack.trackCode.substr(
                0,
                5
              )}_${modulo.agrupaModuloId}_slide${index}`
            "
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 15 : 1"
                class="niu-card-main niu-background niu-text"
                :class="{
                  'niu-dark': infoTrack.isPremium === 0 ? false : true,
                  'niu-light': infoTrack.isPremium === 0 ? true : false
                }"
              >
                <v-row class="pa-0 ma-0" align="center" justify="center">
                  <v-col class="pa-0 ma-0">
                    <v-img
                      :aspect-ratio="17 / 9"
                      :src="loadImage(modulo.moduloImg, modulo.comming_soon)"
                      lazy-src="../../assets/client-loader.gif"
                      style="position:relative !important;"
                    >
                      <div
                        style="position:absolute; z-index:1;top:46%;left:46%;"
                      >
                        <v-btn
                          icon
                          class="niu-play-modulo"
                          @click="activeModulo(modulo)"
                          style="text-shadow: 1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc, 0px -1px #ccc;"
                        >
                          <v-icon size="70px">
                            mdi-play-circle
                          </v-icon>
                        </v-btn>
                      </div>
                      <div style="position:absolute;top:0px;left:0px;">
                        <v-chip
                          class="ma-2"
                          :class="colorFondoModuloClass(modulo)"
                        >
                          Módulo {{ modulo.moduloNumber }}
                        </v-chip>
                      </div>
                    </v-img>
                  </v-col>
                </v-row>
                <v-row align="end" class="pa-2 ma-0 ">
                  <v-col class="ma-0 pa-0 niu-text-carrusel">
                    <div style="position:absolute;top: -35px;right: 20px;">
                      <v-avatar
                        :class="colorFondoModuloClass(modulo)"
                        size="42"
                      >
                        <span class="headline">{{ modulo.moduloNumber }}</span>
                      </v-avatar>
                    </div>
                    <div
                      v-if="!modulo.moduloHtml"
                      class="niu-carousel-texto-modulo"
                    >
                      {{ modulo.moduloTitle }}
                    </div>
                    <span
                      v-else
                      v-html="modulo.moduloTitle"
                      class="niu-carousel-texto-modulo"
                    ></span>
                    <p class="d-none d-sm-flex">
                      <v-icon
                        class="niu-text size-0-8-rem"
                        :class="{
                          ...iconModuloClass(modulo, hover),
                          'niu-dark': infoTrack.isPremium === 0 ? false : true,
                          'niu-light': infoTrack.isPremium === 0 ? true : false
                        }"
                      >
                        mdi-account
                      </v-icon>
                      <label class="niu-text"
                        ><strong>Especialista: </strong>
                        {{ modulo.moduloEspecialista }}</label
                      >
                    </p>
                  </v-col>
                </v-row>
                <v-sheet
                  style="position: absolute;bottom: 15px;width: 90%;left: 5%;"
                  align="center"
                  justify="end"
                  class="transparent"
                  light
                >
                  <small
                    class="niu-text-bold niu-text"
                    :class="{
                      ...iconModuloClass(modulo, hover),
                      'niu-dark': infoTrack.isPremium === 0 ? false : true,
                      'niu-light': infoTrack.isPremium === 0 ? true : false
                    }"
                    >Sesiones: {{ modulo.moduloNroSesiones }}</small
                  >
                  <small
                    class="niu-text-bold ml-4 niu-text"
                    :class="{
                      ...iconModuloClass(modulo, hover),
                      'niu-dark': infoTrack.isPremium === 0 ? false : true,
                      'niu-light': infoTrack.isPremium === 0 ? true : false
                    }"
                    >Tiempo: {{ modulo.moduloDuracion }} minutos</small
                  >
                </v-sheet>
                <div
                  class="niu-barra"
                  :class="barraInferiorModuloCarrusel(modulo, hover)"
                ></div>
                <!-- <div class="niu-numero-modulo">
              {{ modulo.moduloNumber }}
            </div> -->
              </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
/* eslint-disable no-unused-vars */
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'

// import { EventBus } from '../../plugins/event-bus'
import { EventBus } from '../../plugins/event-bus'

export default {
  components: {},
  props: {
    isVitrina: {
      type: Boolean,
      default: false
    },
    infoTrack: {
      type: Object,
      default: () => {
        return {
          trackTitle: '',
          trackCode: '',
          trackId: null,
          trackAreaconocimientoCode: '',
          trackProgreso: null,
          modulos: [],
          isPremium: 0
        }
      }
    },

    trackSesiones: {
      type: Number,
      default: 0
    },
    trackDuracion: {
      type: Number,
      default: 0
    },
    trackAddLearning: {
      type: Number,
      default: 0
    },
    especialista: {
      type: Object,
      default: () => {
        return {
          img: '',
          nickname: ''
        }
      }
    }
  },

  computed: {
    ...mapState(['KnowledgeAreas2']),
    sizeLogoTrack () {
      // 23 / 8
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 30
        case 'sm':
          return 30
        case 'md':
          return 40
        case 'lg':
          return 40
        case 'xl':
          return 40
      }
    }
  },
  // updated () {
  //   // eslint-disable-next-line no-console
  //   console.log(this.infoTrack)
  // },
  // created () {
  //   // eslint-disable-next-line no-console
  //   // console.log(this.trackCode)
  //   // eslint-disable-next-line no-console
  //   console.log(this.infoTrack)
  // },
  async mounted () {
    // // eslint-disable-next-line no-console
    // console.group('newexplora')
    // // eslint-disable-next-line no-console
    // console.log(this.infoTrack.trackAreaconocimientoCode)
    // // eslint-disable-next-line no-console
    // console.groupEnd('newexplora')
    if (this.infoTrack.modulos.length == 0) {
      // eslint-disable-next-line no-console
      // console.log(this.infoTrack)

      this.modulos = (
        await this.$http.get(`/Modulos/Trackid/${this.infoTrack.trackId}`)
      ).data
      // if (this.videonumber != null) {
      //   this.model = this.videonumber
      // }

      // eslint-disable-next-line no-console
      // console.log(this.modulos)
      // eslint-disable-next-line no-console
    } else {
      this.modulos = this.infoTrack.modulos
    }
  },
  data: () => ({
    model: {},
    modulos: [],
    videos: [],
    createRouteDialog: false,

    cart: null,
    degreeTitle: '',
    txtdata: '',
    openCreateDialog: false,
    dialogVerMasInformacion: false
  }),
  methods: {
    ...mapMutations('carruseles', ['SET_MODULO']),
    ...mapMutations([
      'SET_ALERT',
      'SET_ADDRUTATRACK',
      'SET_INFOTRACK',
      'SET_MODALVERMASINFORMACION',
      'SET_CONTENTVERMASINFORMACION'
    ]),
    ver_mas_informacion (track) {
      // eslint-disable-next-line no-console
      console.log(track)
      this.SET_CONTENTVERMASINFORMACION(track)
      this.SET_MODALVERMASINFORMACION(true)
    },
    loadImage (moduloImg, comming_soon) {
      let img = moduloImg.split('.')
      img.pop()
      // eslint-disable-next-line
      // console.log(comming_soon)
      try {
        // let img =
        //   '../../assets/' +
        //   (comming_soon == 1 ? `${img.join('')}_pronto.jpg` : moduloImg)

        // eslint-disable-next-line no-console
        // console.log(img)
        return require('../../assets/' +
          (comming_soon == 1 ? `${img.join('')}_pronto.jpg` : moduloImg))
      } catch (error) {
        return require('../../assets/proximamente.png')
      }
    },
    closeDialog () {
      this.createRouteDialog = false

      this.SET_ADDRUTATRACK({
        modulos: [],
        createRouteDialog: false,
        trackTitle: ''
      })
    },

    openDialog () {
      if (this.infoTrack.trackProgreso == 0) {
        // eslint-disable-next-line no-console
        console.log('openDialog')
        this.SET_ADDRUTATRACK({
          modulos: this.modulos,
          createRouteDialog: true,
          trackTitle: this.infoTrack.trackTitle
        })

        // EventBus.$emit('addRutas', {
        //   modulos: this.modulos,
        //   createRouteDialog: true,
        //   trackTitle: this.trackTitle
        // })
        // this.createRouteDialog = true
      }
    },
    activeModulo (moduloSeleccionado) {
      if (this.isVitrina) {
          this.$router.push('/compraahora');
      } else {
        if (moduloSeleccionado.comming_soon == 0) {
          if (this.videos.lenght > 0) {
            document.documentElement.classList.add('niu-overflow-hidden')
            this.$store.commit('dialog', {
              dialog: true,
              modulo: moduloSeleccionado,
              numberVideo: 0
            })
          }

          this.SET_MODULO(moduloSeleccionado)
          this.SET_INFOTRACK(this.infoTrack)

          let url = `/track/${moduloSeleccionado.trackCode}#mod-${moduloSeleccionado.moduloId}`
          // eslint-disable-next-line no-console
          console.log(url)
          this.$router.push(url)
          // eslint-disable-next-line no-console
          console.log(this.$router, this.$route, this.$routes)
          // this.$router.go(this.)

          // this.$router.push(`/modulo/${this.infoTrack.trackCode}/${moduloSeleccionado.moduloCode}`)
        } else {
          this.SET_ALERT({
            message: `
          <strong style="color:black;">
          Contenido aún no disponible
          </strong>`,
            type: 'warning',
            snackbar: true
          })
        }
      }
    },
    fondoModuloClass (modulo, hover) {
      return {
        'niu-background-gradient-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-gradient-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-gradient-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-gradient-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-gradient-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-gradient-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    iconModuloClass (modulo, hover) {
      return {
        'niu-text-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-text-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-text-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-text-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-text-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-text-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    iconTrackClass (areaconocimientoCode) {
      return {
        'niu-background-solid-emprendimientonegocio':
          areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-solid-liderazgo': areaconocimientoCode == 'liderazgo',
        'niu-background-solid-comunicacion':
          areaconocimientoCode == 'comunicacion',
        'niu-background-solid-desarrollofisico':
          areaconocimientoCode == 'desarrollofisico',
        'niu-background-solid-desarrollopersonal':
          areaconocimientoCode == 'desarrollopersonal',
        'niu-background-solid-conocimientotecnico':
          areaconocimientoCode == 'conocimientotecnico'
      }
    },
    barraInferiorModuloCarrusel (modulo, hover) {
      return {
        'niu-background-solid-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-solid-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-solid-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-solid-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-solid-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-solid-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    colorFondoModuloClass (modulo) {
      return {
        'niu-background-solid-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio',
        'niu-background-solid-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo',
        'niu-background-solid-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion',
        'niu-background-solid-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico',
        'niu-background-solid-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal',
        'niu-background-solid-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.niu-border-explora {
  border: 2px solid #c3c3c3;
  border-radius: 15px;
}
$btn-text-transform: capitalize !default;
$fondo: white;
$fondo_card: black;
.niu-dark.niu-background {
  background-color: black !important;
}
.niu-dark.niu-text {
  color: white !important;
}
.niu-light.niu-background {
  background-color: #f5f5f5 !important;
}
.niu-light.niu-text {
  color: black !important;
}
// medida de 0.2rem por unidad
.niu-text.size-7-rem {
  font-size: 1.4rem;
}
.niu-text.size-0-8-rem {
  font-size: 0.8rem;
}
.niu-back-dialog-0 {
  background-color: #a52882 !important;
}
.niu-back-dialog-1 {
  background-color: #a50b7a !important;
}
.niu-back-dialog-2 {
  background-color: #590d53 !important;
}
.niu-iconos {
  font-size: 1.2rem;
  font-weight: normal;
  color: #717171;
}
.niu-label {
  display: inline-block;
  border-radius: 10px;
  line-height: 5px;
  padding: 10px;
  position: absolute;
  top: 0;
}

.niu-transparent-explora-modulos {
  background-color: $fondo !important;
}
.niu-numero-modulo {
  position: absolute;
  font-size: 16rem;
  top: 10%;
  left: 85%;
  text-shadow: -3px -3px #000;
  font-weight: bold;
}
.theme--dark.v-btn.v-btn--icon.niu-btn-disabled {
  color: transparent !important;
}
.v-responsive.v-image {
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0;
}
.texto_modulo {
  transform: rotate(-45deg);
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px #000;
}
.box_vineta {
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  background: linear-gradient(
    -45deg,
    transparent 0,
    transparent 70%,
    rgba(0, 0, 0, 0.75) 0,
    rgba(0, 0, 0, 0.75) 30%
  );
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0;
}
// $width: ;
// * {
//   position: relative;
// }

.niu-rounded-4 {
  border-radius: 4px;
}
.niu-rounded-tl-4 {
  border-top-left-radius: 4px;
}
.niu-rounded-tr-4 {
  border-top-right-radius: 4px;
}
.niu-rounded-br-4 {
  border-bottom-right-radius: 4px;
}
.niu-rounded-bl-4 {
  border-bottom-left-radius: 4px;
}
.niu-rounded-b-4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.niu-rounded-t-4 {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.v-sheet.niu-transparent {
  background-color: transparent;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0),
    0px 8px 10px 1px rgba(0, 0, 0, 0), 0px 3px 14px 2px rgba(0, 0, 0, 0) !important;
}
.niu-title-carousel {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.niu-position-relative {
  position: relative;
}
.niu-overflow-hidden {
  overflow: hidden;
}
.niu-text-carrusel {
  min-height: 100px;
}

$ancho: 300px;
$alto: 430px;
$altoSlid: 750px;

.text-track-title {
  color: black;
  font-size: 20px;
  display: inline-block;
  padding: 0 20px;
  position: relative;
  font-weight: bold;
}

.niu-width-track {
  width: 96%;
  left: 2%;
  height: 300px;
}
.niu-card-main {
  width: 275px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 15px;
  border-radius: 16px !important;
  position: relative;
  height: 270px;
}
.niu-iconos {
  font-size: 1rem;
  font-weight: normal;
  color: #717171;
}
@media (max-width: 960px) and (min-width: 600px) {
  $fooPercent: 10%;
  .text-track-title {
    color: black;
    font-size: 22px;
    display: inline-block;
    width: 70%;
    position: relative;
    left: 55px;
    font-weight: bold;
  }
  // .niu-card-main {
  //   width: $ancho * ((100% - $fooPercent) / 100%);
  //   height: 320px;
  //   margin-right: 15px;
  //   margin-bottom: 15px * ((100% - $fooPercent) / 100%);
  //   // padding: 2px;
  //   // background-color: transparent;
  //   // border: 1px solid #000;
  //   border-radius: 16px * ((100% - $fooPercent) / 100%) !important;
  // }
  // .niu-width-track {
  //   width: 96%;
  //   left: 2%;
  //   height: 570px;
  // }
  .niu-iconos {
    font-size: 1rem;
    font-weight: normal;
    color: #717171;
  }
}
@media (max-width: 600px) and (min-width: 400px) {
  $fooPercent: 3%;
  .text-track-title {
    color: black;
    font-size: 22px;
    display: inline-block;
    width: 70%;
    position: relative;
    left: 55px;
    font-weight: bold;
  }
  .niu-iconos {
    font-size: 1rem;
    font-weight: normal;
    color: #717171;
  }
  // .niu-card-main {
  //   font-size: 10px;
  //   width: $ancho * ((100% - $fooPercent) / 100%);
  //   height: $alto * ((100% - $fooPercent) / 100%);
  //   margin-right: 20px;
  //   margin-bottom: 15px * ((100% - $fooPercent) / 100%);
  //   // padding: 2px;
  //   // background-color: transparent;
  //   // border: 1px solid #000;
  //   border-radius: 16px * ((100% - $fooPercent) / 100%) !important;
  // }
  // .niu-width-track {
  //   width: 96%;
  //   left: 2%;
  //   height: 650px;
  // }
}
@media (max-width: 400px) and (min-width: 300px) {
  .text-track-title {
    color: black;
    font-size: 20px;
    display: inline-block;
    width: 70%;
    position: relative;
    left: 55px;
    font-weight: bold;
  }
  .niu-card-main {
    height: 260px;
    width: 210px;
  }
  .niu-width-track {
    width: 96%;
    height: 600px;
  }
  .text-track-title {
    color: black;
    font-size: 18px;
    display: inline-block;
    width: 70%;
    position: relative;
    left: 55px;
    font-weight: bold;
  }
  .niu-iconos {
    font-size: 0.9rem;
    font-weight: normal;
    color: #717171;
    left: -21%;
  }
  .niu-iconos > label {
    display: block;
  }
  .niu-label {
    display: inline-block;
    border-radius: 10px;
    line-height: 5px;
    padding: 10px;
    position: absolute;
    top: 0;
    font-size: 12px;
  }
}
@media (max-width: 300px) {
  .niu-card-main {
    height: 260px;
    width: 210px;
  }
  .niu-width-track {
    width: 96%;
    height: 600px;
  }
  .text-track-title {
    color: black;
    font-size: 18px;
    display: inline-block;
    width: 70%;
    position: relative;
    left: 55px;
    font-weight: bold;
  }
  .niu-iconos {
    font-size: 0.9rem;
    font-weight: normal;
    color: #717171;
    left: -25%;
  }
  .niu-iconos > label {
    display: block;
  }
  .niu-label {
    display: inline-block;
    border-radius: 10px;
    line-height: 5px;
    padding: 10px;
    position: absolute;
    top: 0;
    font-size: 12px;
  }
}
.niu-carousel-texto-modulo {
  text-align: left;
  /* bottom: 0; */
  /* height: 60px; */
  padding: 6px;
  font-size: 0.9rem;
  font-weight: 700;
  word-break: break-word;
  text-transform: none;
  /* margin-bottom: 40px; */
  display: block;
  // height: 65px;
}
.niu-carousel-texto-modulo-xs {
  text-align: left;
  /* bottom: 0; */
  /* height: 60px; */
  /* padding: 4px; */
  font-size: 1.1rem;
  font-weight: 700;
  word-break: break-word;
  text-transform: none;
  /* margin-bottom: 40px; */
  display: block;
}
.cicle-ball-area {
  width: 10px;
  height: 10px;
  background: white;
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px 0 0;
}
.v-icon.notranslate.mdi.mdi-chevron-left,
.v-icon.notranslate.mdi.mdi-chevron-right {
  font-size: 70px !important;
}
/* .niu-width-track {
  max-width: 100%;
} */
// @media (min-width: 300px) and (max-width: 320px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 280px;
//   }
// }
// @media (min-width: 320px) and (max-width: 340px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 300px;
//   }
// }
// @media (min-width: 340px) and (max-width: 380px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 380px) and (max-width: 400px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 360px;
//   }
// }
// @media (min-width: 400px) and (max-width: 420px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 420px) and (max-width: 440px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 340px;
//   }
// }
// @media (min-width: 440px) and (max-width: 460px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 365px;
//   }
// }
// @media (min-width: 460px) and (max-width: 480px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 385px;
//   }
// }
// @media (min-width: 480px) and (max-width: 500px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 405px;
//   }
// }
// @media (min-width: 500px) and (max-width: 520px) {
//   .niu-card-main {
//     height: 175px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 465px;
//     height: 240px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 520px) and (max-width: 540px) {
//   .niu-card-main {
//     height: 130px;
//     width: 158px;
//   }
//   .niu-width-track {
//     width: 495px;
//     height: 250px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 540px) and (max-width: 560px) {
//   .niu-card-main {
//     height: 199px;
//     width: 224px;
//   }
//   .niu-width-track {
//     width: 465px;
//   }
// }
// @media (min-width: 560px) and (max-width: 580px) {
//   .niu-card-main {
//     height: 199px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 485px;
//   }
// }
// @media (min-width: 580px) and (max-width: 600px) {
//   .niu-card-main {
//     height: 199px;
//     width: 244px;
//   }
//   .niu-width-track {
//     width: 505px;
//   }
// }
// @media (min-width: 600px) and (max-width: 620px) {
//   .niu-card-main {
//     height: 199px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 525px;
//   }
// }
// @media (min-width: 620px) and (max-width: 640px) {
//   .niu-card-main {
//     height: 191px;
//     width: 261px;
//   }
//   .niu-width-track {
//     width: 545px;
//   }
// }
// @media (min-width: 640px) and (max-width: 660px) {
//   .niu-card-main {
//     height: 214px;
//     width: 273px;
//   }
//   .niu-width-track {
//     width: 565px;
//   }
// }
// @media (min-width: 660px) and (max-width: 680px) {
//   .niu-card-main {
//     height: 282px;
//     width: 188px;
//   }
//   .niu-width-track {
//     width: 585px;
//   }
//   .v-slide-group {
//     height: 300px;
//   }
// }
// @media (min-width: 680px) and (max-width: 700px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 605px;
//   }
// }
// @media (min-width: 700px) and (max-width: 720px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 625;
//   }
// }
// @media (min-width: 720px) and (max-width: 740px) {
//   .niu-card-main {
//     height: 157px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 645;
//   }
// }
// @media (min-width: 740px) and (max-width: 760px) {
//   .niu-card-main {
//     height: 165px;
//     width: 212px;
//   }
//   .niu-width-track {
//     width: 665px;
//   }
// }
// @media (min-width: 760px) and (max-width: 780px) {
//   .niu-card-main {
//     height: 171px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 685px;
//   }
// }
// @media (min-width: 780px) and (max-width: 800px) {
//   .niu-card-main {
//     height: 179px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 705px;
//   }
// }
// @media (min-width: 800px) and (max-width: 820px) {
//   .niu-card-main {
//     height: 182px;
//     width: 232px;
//   }
//   .niu-width-track {
//     width: 725px;
//   }
// }
// @media (min-width: 820px) and (max-width: 840px) {
//   .niu-card-main {
//     height: 187px;
//     width: 239px;
//   }
//   .niu-width-track {
//     width: 745px;
//   }
// }
// @media (min-width: 840px) and (max-width: 860px) {
//   .niu-card-main {
//     height: 143px;
//     width: 183px;
//   }
//   .niu-width-track {
//     width: 765px;
//   }
// }
// @media (min-width: 860px) and (max-width: 880px) {
//   .niu-card-main {
//     height: 202px;
//     width: 252px;
//   }
//   .niu-width-track {
//     width: 785px;
//   }
// }
// @media (min-width: 880px) and (max-width: 900px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 805px;
//   }
// }
// @media (min-width: 900px) and (max-width: 920px) {
//   .niu-card-main {
//     height: 206px;
//     width: 266px;
//   }
//   .niu-width-track {
//     width: 825px;
//   }
// }
// @media (min-width: 920px) and (max-width: 940px) {
//   .niu-card-main {
//     height: 156px;
//     width: 202px;
//   }
//   .niu-width-track {
//     width: 845px;
//   }
// }
// @media (min-width: 940px) and (max-width: 960px) {
//   .niu-card-main {
//     height: 167px;
//     width: 209px;
//   }
//   .niu-width-track {
//     width: 865px;
//   }
// }
// @media (min-width: 960px) and (max-width: 980px) {
//   .niu-card-main {
//     height: 169px;
//     width: 213px;
//   }
//   .niu-width-track {
//     width: 885px;
//   }
// }
// @media (min-width: 980px) and (max-width: 1000px) {
//   .niu-card-main {
//     height: 173px;
//     width: 217px;
//   }
//   .niu-width-track {
//     width: 905px;
//   }
// }
// @media (min-width: 1000px) and (max-width: 1020px) {
//   .niu-card-main {
//     height: 170px;
//     width: 218px;
//   }
//   .niu-width-track {
//     width: 910px;
//   }
// }
// @media (min-width: 1020px) and (max-width: 1040px) {
//   .niu-card-main {
//     height: 178px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 945px;
//   }
// }
// @media (min-width: 1040px) and (max-width: 1060px) {
//   .niu-card-main {
//     height: 181px;
//     width: 229px;
//   }
//   .niu-width-track {
//     width: 954px;
//   }
// }
// @media (min-width: 1060px) and (max-width: 1080px) {
//   .niu-card-main {
//     height: 182px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 974px;
//   }
// }
// @media (min-width: 1080px) and (max-width: 1100px) {
//   .niu-card-main {
//     height: 183px;
//     width: 237px;
//   }
//   .niu-width-track {
//     width: 985px;
//   }
// }
// @media (min-width: 1100px) and (max-width: 1120px) {
//   .niu-card-main {
//     height: 187px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1000px;
//   }
// }
// @media (min-width: 1120px) and (max-width: 1140px) {
//   .niu-card-main {
//     height: 190px;
//     width: 246px;
//   }
//   .niu-width-track {
//     width: 1020px;
//   }
// }
// @media (min-width: 1140px) and (max-width: 1160px) {
//   .niu-card-main {
//     height: 194px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1040px;
//   }
// }
// @media (min-width: 1160px) and (max-width: 1180px) {
//   .niu-card-main {
//     height: 159px;
//     width: 203px;
//   }
//   .niu-width-track {
//     width: 1060px;
//   }
// }
// @media (min-width: 1180px) and (max-width: 1200px) {
//   .niu-card-main {
//     height: 205px;
//     width: 262px;
//   }
//   .niu-width-track {
//     width: 1080px;
//   }
// }
// @media (min-width: 1200px) and (max-width: 1220px) {
//   .niu-card-main {
//     height: 209px;
//     width: 267px;
//   }
//   .niu-width-track {
//     width: 1100px;
//   }
// }
// @media (min-width: 1220px) and (max-width: 1240px) {
//   .niu-card-main {
//     height: 210px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1120px;
//   }
// }
// @media (min-width: 1240px) and (max-width: 1260px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1260px) and (max-width: 1280px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1280px) and (max-width: 1300px) {
//   .niu-card-main {
//     height: 173px;
//     width: 223px;
//   }
//   .niu-width-track {
//     width: 1160px;
//   }
// }
// @media (min-width: 1300px) and (max-width: 1320px) {
//   .niu-card-main {
//     height: 177px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 1180px;
//   }
// }
// @media (min-width: 1320px) and (max-width: 1340px) {
//   .niu-card-main {
//     height: 180px;
//     width: 231px;
//   }
//   .niu-width-track {
//     width: 1200px;
//   }
// }
// @media (min-width: 1340px) and (max-width: 1360px) {
//   .niu-card-main {
//     height: 184px;
//     width: 236px;
//   }
//   .niu-width-track {
//     width: 1220px;
//   }
// }
// @media (min-width: 1360px) and (max-width: 1380px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1380px) and (max-width: 1400px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1400px) and (max-width: 1420px) {
//   .niu-card-main {
//     height: 189px;
//     width: 243px;
//   }
//   .niu-width-track {
//     width: 1260px;
//   }
// }
// @media (min-width: 1420px) and (max-width: 1440px) {
//   .niu-card-main {
//     height: 192px;
//     width: 247px;
//   }
//   .niu-width-track {
//     width: 1280px;
//   }
// }
// @media (min-width: 1440px) and (max-width: 1460px) {
//   .niu-card-main {
//     height: 195px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1300px;
//   }
// }
// @media (min-width: 1460px) and (max-width: 1480px) {
//   .niu-card-main {
//     height: 197px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 1320px;
//   }
// }
// @media (min-width: 1480px) and (max-width: 1500px) {
//   .niu-card-main {
//     height: 201px;
//     width: 259px;
//   }
//   .niu-width-track {
//     width: 1340px;
//   }
// }
// @media (min-width: 1500px) and (max-width: 1520px) {
//   .niu-card-main {
//     height: 205px;
//     width: 263px;
//   }
//   .niu-width-track {
//     width: 1360px;
//   }
// }
// @media (min-width: 1520px) and (max-width: 1540px) {
//   .niu-card-main {
//     height: 230px;
//     width: 290px;
//   }
//   .niu-width-track {
//     width: 1490px;
//     height: 300px;
//   }
// }
// @media (min-width: 1540px) and (max-width: 1560px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1400px;
//   }
// }
// @media (min-width: 1560px) and (max-width: 1580px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1420px;
//   }
// }
// @media (min-width: 1580px) and (max-width: 1600px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1440px;
//   }
// }
// @media (min-width: 1600px) and (max-width: 1620px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1460px;
//   }
// }
// @media (min-width: 1620px) and (max-width: 1640px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1480px;
//   }
// }
// @media (min-width: 1640px) and (max-width: 1660px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1500px;
//   }
// }
// @media (min-width: 1660px) and (max-width: 1680px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1520px;
//   }
// }
// @media (min-width: 1680px) and (max-width: 1700px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1540px;
//   }
// }
// @media (min-width: 1700px) and (max-width: 1720px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1560px;
//   }
// }
// @media (min-width: 1720px) and (max-width: 1740px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1580px;
//   }
// }
// @media (min-width: 1740px) and (max-width: 1760px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1600px;
//   }
// }
// @media (min-width: 1760px) and (max-width: 1780px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1620px;
//   }
// }
// @media (min-width: 1780px) and (max-width: 1800px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1640px;
//   }
// }
// @media (min-width: 1800px) and (max-width: 1820px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1660px;
//   }
// }
// @media (min-width: 1820px) and (max-width: 1840px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1680px;
//   }
// }
// @media (min-width: 1840px) and (max-width: 1860px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1700px;
//   }
// }
// @media (min-width: 1860px) and (max-width: 1880px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1720px;
//   }
// }
// @media (min-width: 1880px) and (max-width: 1900px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1740px;
//   }
// }
// @media (min-width: 1900px) and (max-width: 1920px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1760px;
//   }
// }
// .theme--dark.v-sheet.niu-card-main {
//   background-color: #333333;
//   border-color: #333333;
// }
.niu-hover {
  border-radius: 8px;
  border: 3px solid #444;
  border-radius: 8px;
}
.niu-border-emprendimientonegocio-dark {
  border-color: #ffb92e !important;
}
.niu-border-emprendimientonegocio-light {
  border-color: #ffb92e !important;
}
.niu-border-liderazgo-dark {
  border-color: #c10000 !important;
}
.niu-border-liderazgo-light {
  border-color: #c10000 !important;
}
.niu-border-desarrollofisico-dark {
  border-color: #fc3a72 !important;
}
.niu-border-desarrollofisico-light {
  border-color: #fc3a72 !important;
}
.niu-border-desarrollopersonal-dark {
  border-color: #8ac445 !important;
}
.niu-border-desarrollopersonal-light {
  border-color: #8ac445 !important;
}
.niu-border-conocimientotecnico-dark {
  border-color: #40ccb1 !important;
}
.niu-border-conocimientotecnico-light {
  border-color: #40ccb1 !important;
}
.niu-border-comunicacion-dark {
  border-color: #354cf9 !important;
}
.niu-border-comunicacion-light {
  border-color: #354cf9 !important;
}

.niu-barra {
  width: 70%;
  height: 5px;
  background-color: black;
  position: absolute;
  bottom: 0;
  z-index: 20;
  border-radius: 2px;
  left: 15%;
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 70px;
  border: 1px solid;
  border-radius: 50%;
}
.niu-background-gradient-emprendimientonegocio {
  background: rgb(255, 174, 4);
  background: linear-gradient(45deg, rgba(255, 174, 4, 1) 0%, #ffb92e 100%);
}
.niu-background-gradient-liderazgo {
  background: rgb(246, 70, 0);
  background: linear-gradient(45deg, rgba(246, 70, 0, 1) 0%, #c10000 100%);
}
.niu-background-gradient-desarrollofisico {
  background: #fc3a72;
  background: linear-gradient(
    45deg,
    rgba(119, 0, 5, 1) 0%,
    rgba(244, 0, 0, 1) 100%
  );
}
.niu-background-gradient-desarrollopersonal {
  background: #8ac445;
  background: linear-gradient(
    45deg,
    rgba(45, 107, 0, 1) 0%,
    rgba(42, 232, 45, 1) 100%
  );
}
.niu-background-gradient-conocimientotecnico {
  background: rgb(17, 178, 156);
  background: linear-gradient(45deg, #40ccb1 0%, rgba(62, 255, 232, 1) 100%);
}
.niu-background-gradient-comunicacion {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
}

.niu-background-gradient-emprendimientonegocio {
  background: #ffb92e;
  background: linear-gradient(45deg, #ffb92e 0%, rgba(255, 174, 4, 1) 100%);
}
.niu-background-gradient-liderazgo {
  background: #c10000;
  background: linear-gradient(45deg, #c10000 0%, rgba(246, 70, 0, 1) 100%);
}
.niu-background-gradient-desarrollofisico {
  background: rgba(244, 0, 0, 1);
  background: linear-gradient(
    45deg,
    rgba(244, 0, 0, 1) 0%,
    rgba(119, 0, 5, 1) 100%
  );
}
.niu-background-gradient-desarrollopersonal {
  background: rgba(42, 232, 45, 1);
  background: linear-gradient(
    45deg,
    rgba(42, 232, 45, 1) 0%,
    rgba(45, 107, 0, 1) 100%
  );
}
.niu-background-gradient-conocimientotecnico {
  background: rgba(62, 255, 232, 1);
  background: linear-gradient(45deg, rgba(62, 255, 232, 1) 0%, #40ccb1 100%);
}
.niu-background-gradient-comunicacion {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 70px !important;
  color: #ababab !important;
}

$emprendimientonegocio: #ffb92e;
$liderazgo: #c10000;
$desarrollofisico: #fc3a72;
$desarrollopersonal: #8ac445;
$conocimientotecnico: #40ccb1;
$comunicacion: #354cf9;

.niu-text {
  font-size: 0.9rem;
}

.niu-text-bold {
  font-weight: bold;
  font-size: 0.9rem;
}

.niu-text-black {
  color: rgba(0, 0, 0, 1) !important;
}
.niu-dark.niu-text.niu-text-emprendimientonegocio,
.niu-light.niu-text.niu-text-emprendimientonegocio {
  color: $emprendimientonegocio !important;
}
.niu-dark.niu-text.niu-text-liderazgo,
.niu-light.niu-text.niu-text-liderazgo {
  color: $liderazgo !important;
}
.niu-dark.niu-text.niu-text-desarrollofisico,
.niu-light.niu-text.niu-text-desarrollofisico {
  color: $desarrollofisico !important;
}
.niu-dark.niu-text.niu-text-desarrollopersonal,
.niu-light.niu-text.niu-text-desarrollopersonal {
  color: $desarrollopersonal !important;
}
.niu-dark.niu-text.niu-text-conocimientotecnico,
.niu-light.niu-text.niu-text-conocimientotecnico {
  color: $conocimientotecnico !important;
}
.niu-dark.niu-text.niu-text-comunicacion,
.niu-light.niu-text.niu-text-comunicacion {
  color: $comunicacion !important;
}

.v-chip.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
  background: $emprendimientonegocio !important;
}
.v-chip.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
  background: $liderazgo !important;
}
.v-chip.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
  background: $desarrollofisico !important;
}
.v-chip.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
  background: $desarrollopersonal !important;
}
.v-chip.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
  background: $conocimientotecnico !important;
}
.v-chip.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
  background: $comunicacion !important;
}
.v-chip.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
  color: white !important;
}
.v-chip.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
  color: white !important;
}
.v-chip.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
  color: white !important;
}
.v-chip.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
  color: white !important;
}
.v-chip.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
  color: white !important;
}
.v-chip.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
  color: white !important;
}

.v-application p {
  margin-bottom: 4px !important;
}
.niu-play-modulo {
  color: white !important;
}
.niu-play-modulo:hover {
  color: #ddd !important;
}
</style>
