var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modulos.length > 0)?_c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"masterclass",staticClass:"rounded-lg rounded",style:({
		position: 'relative',
		backgroundColor: '#FFF',
		color: '#462054',
		height: (_vm.heightMasterClass + "px"),
		'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
		'niu-light': _vm.infoTrack.isPremium === 0 ? true : false,
	}),attrs:{"light":"","elevation":"0"}},[_c('v-row',{style:({ height: (_vm.heightMasterClass + "px") })},[_c('v-col',{style:({ height: (_vm.imageHeight + "px") }),attrs:{"cols":"12","sm":"12","md":"8","lg":"8","xl":"8"}},[_c('v-img',{staticClass:"rounded-l-lg rounded-r-0 pa-0 ma-0 rounded",style:({
					height: (_vm.imageHeight + "px"),
					borderRadius:
						this.$vuetify.breakpoint.name == 'xs' ? '10px !important' : '',
				}),attrs:{"aspect-ratio":_vm.aspectoImg,"src":require('../../assets/' + _vm.modulo.moduloImg),"lazy-src":require("../../assets/client-loader.gif"),"width":"100%"}},[_c('div',{staticClass:"niu-icono-contenido-premium"},[_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.infoTrack.isPremium === 0
									? require('../../assets/weblogo_n.png')
									: require('../../assets/logo_premium_150x150.png')}})],1)],1),(_vm.infoTrack.track_type_curso)?_c('div',{staticClass:"rounded-pill ma-3 pa-1 btn-classmaster d-flex d-md-flex",staticStyle:{"position":"absolute","top":"0","right":"0px"}},[_c('label',{staticClass:"rounded-pill"},[(_vm.infoTrack.track_type_curso == 1)?_c('span',[_vm._v(" Mentoría ")]):(_vm.infoTrack.track_type_curso == 2)?_c('span',[_vm._v(" Capacitación ")]):(_vm.infoTrack.track_type_curso == 3)?_c('span',[_vm._v(" Masterclass ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"white--text pa-4 d-flex d-md-flex niu-title-masterclass",staticStyle:{"font-size":"16px"},attrs:{"id":_vm.infoTrack.trackId}},[_vm._v(" "+_vm._s(((_vm.infoTrack.trackTitle) + " "))+" ")]),_c('label',{staticClass:"niu-text niu-text-bold white--text",staticStyle:{"position":"absolute","bottom":"20px","left":"20px","font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.modulo.moduloEspecialista)+" ")]),(
						(_vm.modulo.isPremium == 1 && _vm.membresia == 2) ||
						(_vm.modulo.isPremium == 0 && _vm.membresia == 1) ||
						_vm.is_team == true
					)?_c('v-btn',{staticClass:"niu-play-modulo niu-play-masterclass",attrs:{"icon":""},on:{"click":function($event){return _vm.activeModulo(_vm.modulo)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.name == 'xs' ? 30 : 60}},[_vm._v(" mdi-play-circle ")])],1):_c('v-btn',{staticClass:"niu-play-modulo niu-play-masterclass",attrs:{"icon":""},on:{"click":_vm.activarModel}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.name == 'xs' ? 40 : 80}},[_vm._v(" mdi-play-circle ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-row',[_c('v-col',[(_vm.infoTrack.track_type_curso)?_c('div',{staticClass:"rounded-pill btn-classmaster d-none d-sm-none d-md-flex"},[(_vm.infoTrack.track_type_curso)?_c('label',{staticClass:"rounded-pill"},[(_vm.infoTrack.track_type_curso == 1)?_c('span',[_vm._v(" Mentoría ")]):(_vm.infoTrack.track_type_curso == 2)?_c('span',[_vm._v(" Capacitación ")]):(_vm.infoTrack.track_type_curso == 3)?_c('span',[_vm._v(" Master Class ")]):_vm._e()]):_vm._e()]):_vm._e(),_c('h3',{staticClass:"d-none d-none d-sm-none d-md-flex",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(((_vm.infoTrack.trackTitle) + " "))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","justify":"center","align":"center"}},[(
							(_vm.modulo.isPremium == 1 && _vm.membresia == 2) ||
							(_vm.modulo.isPremium == 0 && _vm.membresia == 1) ||
							_vm.is_team == true
						)?_c('v-btn',{attrs:{"color":_vm.$vuetify.theme.defaults.light.secondary},on:{"click":function($event){return _vm.activeModulo(_vm.modulo)}}},[_c('span',{staticStyle:{"color":"#fff","font-size":"12px"}},[_vm._v(" Ver "),(_vm.infoTrack.track_type_curso == 1)?_c('span',[_vm._v(" la Mentoría ")]):(_vm.infoTrack.track_type_curso == 2)?_c('span',[_vm._v(" la Capacitación ")]):(_vm.infoTrack.track_type_curso == 3)?_c('span',[_vm._v(" la Master Class ")]):_vm._e()])]):_c('v-btn',{attrs:{"color":_vm.$vuetify.theme.defaults.light.secondary}},[_c('label',{on:{"click":_vm.activaModelPremium}},[_c('span',{staticStyle:{"color":"#fff","font-size":"12px"}},[_vm._v(" Ver "),(_vm.infoTrack.track_type_curso == 1)?_c('span',[_vm._v(" la Mentoría ")]):(_vm.infoTrack.track_type_curso == 2)?_c('span',[_vm._v(" la Capacitación ")]):(_vm.infoTrack.track_type_curso == 3)?_c('span',[_vm._v(" la Master Class ")]):_vm._e()])])])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }