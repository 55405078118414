<template>
	<v-row v-resize="resizeBanner" class="mt-md-11 pt-md-12 pt-5 mt-5 pb-5">
		<v-col>
			<v-row
				:style="`height:
                  ${isVideo ? altoVideo : altoBannerExplora}px;`"
			>
				<v-col class="ma-0 pa-0 fill-height" style="position: relative">
					<!-- <div style="position:fixed;
            width:50px;
            height:80px;
            z-index:10;
            color:white;
            text-shadow: 1px 1px 1px black,-1px -1px 1px black,1px -1px 1px black;">
            {{ $vuetify.breakpoint.name }}
          </div> -->
					<keep-alive>
						<NiuBanner
							:is_compraahora="true"
							:altoBannerExplora="altoBannerExplora"
							@isVideo="
								(isVideo) => {
									this.isVideo = isVideo;
								}
							"
							:isPremium="true"
						></NiuBanner>
					</keep-alive>
					<v-btn
						style="position: absolute; top: 5%; right: 5%"
						elevation="0"
						class="transparent"
						href="https://api.whatsapp.com/send?phone=56945764156&text=%20%C2%A1Hola!%20Quiero%20comprar%20ahora%20Niufleex%20Premium"
						target="_blank"
					>
						<img
							src="../assets/iconos/whatsapp.png"
							style="width: 50px; position: relative; left: 10px"
							alt=""
						/>
						<label class="rounded-r-xl niu-link-soporte">
							Soporte de Ventas
						</label>
					</v-btn>
					<NiuDialogVideo></NiuDialogVideo>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="8">
					<v-row>
						<v-col cols="5">
							<h1 :style="`color:${$vuetify.theme.defaults.light.primary}`">
								+ 400 Videos
							</h1>
							<p :style="`color:${$vuetify.theme.defaults.light.primary}`">
								Contamos con más de 33 cursos 6 categorías, masterclass,
								mentorías, eventos en vivo y contenido descargable
							</p>
						</v-col>
						<v-col cols="7">
							<v-row>
								<v-col cols="12" md="6" sm="12">
									<v-img
										src="https://niufleex.com/wp-content/uploads/2021/09/ventas-posr-wsp.jpg"
									></v-img>
								</v-col>
								<v-col cols="12" md="6" sm="12">
									<v-img
										src="https://niufleex.com/wp-content/uploads/2021/09/speaker.jpg"
									></v-img>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6" sm="12">
									<v-img
										src="https://niufleex.com/wp-content/uploads/2021/09/Todo-se-basa-en-creencias.jpg"
									></v-img>
								</v-col>
								<v-col cols="12" md="6" sm="12">
									<v-img
										src="https://niufleex.com/wp-content/uploads/2021/09/portada.jpg"
									></v-img>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row>
				<v-col style="text-align: center" cols="8">
					<label
						style="text-align: center"
						:style="`color:${$vuetify.theme.defaults.light.primary}`"
					>
						¿Por qué aprender en <strong>NIUFLEEX</strong> ?
					</label>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="8">
					<v-row>
						<v-col>
							<v-card
								elevation="0"
								class="transparent"
								style="text-align: center"
							>
								<v-img
									style="margin: 0 auto"
									width="50%"
									src="../assets/iconos/modeloeducativo.svg"
								></v-img>
								<v-card-text
									:style="`color:${$vuetify.theme.defaults.light.primary}`"
								>
									El <strong>Modelo Educativo</strong> está desarrollado para un
									espacio virtual de aprendizaje
								</v-card-text>
							</v-card>
						</v-col>
						<v-col>
							<v-card
								elevation="0"
								class="transparent"
								style="text-align: center"
							>
								<v-img
									style="margin: 0 auto"
									width="50%"
									src="../assets/iconos/propositodevida.svg"
								></v-img>
								<v-card-text
									:style="`color:${$vuetify.theme.defaults.light.primary}`"
								>
									Nuestro foco está en el <strong>Ser Humano</strong> y su
									<strong>Propósito de Vida</strong>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col>
							<v-card
								elevation="0"
								class="transparent"
								style="text-align: center"
							>
								<v-img
									style="margin: 0 auto"
									width="50%"
									src="../assets/iconos/rutasdeaprendizaje.svg"
								></v-img>
								<v-card-text
									:style="`color:${$vuetify.theme.defaults.light.primary}`"
								>
									Podrás crear <strong>Rutas de Aprendizaje</strong> según tus
									aptitudes e intereses
								</v-card-text>
							</v-card>
						</v-col>
						<v-col>
							<v-card
								elevation="0"
								class="transparent"
								style="text-align: center"
							>
								<v-img
									style="margin: 0 auto"
									width="50%"
									src="../assets/iconos/especialistas.svg"
								></v-img>
								<v-card-text
									:style="`color:${$vuetify.theme.defaults.light.primary}`"
								>
									Aprenderás de
									<strong>
										especialistas, líderes inspiradores y mentores
									</strong>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row
				justify="center"
				:style="`background: ${$vuetify.theme.defaults.light.primary};
background: linear-gradient(355deg, ${$vuetify.theme.defaults.light.primary} 0%, ${$vuetify.theme.defaults.light.primary} 47%, rgba(255,255,255,1) 47%, rgba(255,255,255,0) 100%);`"
			>
				<v-col cols="8">
					<v-img
						src="https://niufleex.com/wp-content/uploads/2021/12/mockup2.png"
					>
					</v-img>
				</v-col>
			</v-row>
			<v-row
				justify="center"
				:style="`background: ${$vuetify.theme.defaults.light.primary};`"
				cols="8"
				style="text-align: center"
			>
				<v-col>
					<v-row>
						<v-col>
							<v-img
								src="https://niufleex.com/wp-content/uploads/2021/10/membresias.png"
							>
							</v-img>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<img
								width="20%"
								src="https://niufleex.com/wp-content/uploads/2021/10/Teoma_Logo-1024x1024-1.png"
								alt=""
							/>
							<div
								style="width: 80%; display: inline; font-size: 2em; color: #fff"
							>
								Accede a tu <strong>Back Office de TEOMA</strong> y elige tu
								membresía desde el
								<strong>siguiente botón</strong>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row
				justify="center"
				:style="`background: ${$vuetify.theme.defaults.light.primary};`"
				style="text-align: center"
			>
				<v-col cols="8" style="text-align: center">
					<v-btn
						class="transparent"
						style="text-align: center"
						href="https://backoffice.teomacorp.com/login"
					>
						<img
							style="height: 50px"
							:src="require('../assets/iconos/comprarahora.svg')"
							alt=""
						/>
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import NiuBanner from "../components/shared/NiuBanner.vue";
import NiuDialogVideo from "../components/explora/dialogVideo.vue";
export default {
	name: "CompraAhora",
	components: {
		NiuBanner,
		NiuDialogVideo,
	},
	data: () => {
		return {
			isVideo: {},
			altoBannerExplora: 200,
			altoVideo: 500,
		};
	},
	mounted() {},
	methods: {
		resizeBanner() {
			if (document.querySelector("#banner")) {
				let aspecto = 13 / 39;

				// switch (this.$vuetify.breakpoint.name) {
				//   case 'xs':
				//     aspecto = 13 / 39
				//     break
				//   case 'sm':
				//     aspecto = 13 / 39
				//     break
				//   case 'md':
				//     aspecto = 13 / 39
				//     break
				//   case 'lg':
				//     aspecto = 13 / 39
				//     break
				//   case 'xl':
				//     aspecto = 13 / 39
				//     break
				// }

				this.altoBannerExplora =
					document.querySelector("#banner").offsetWidth * aspecto;

				this.altoVideo =
					(document.querySelector("#banner").offsetWidth * 9) / 16;
			}
		},
	},
};
</script>
<style lang="scss">
.niu-link-soporte {
	background-color: rgb(255 255 255 / 20%);
	line-height: 20px;
	padding: 2px 10px;
	color: white;
	font-weight: bold;
}

@media (max-width: 600px) {
	.niu-link-soporte {
		background-color: rgb(255 255 255 / 80%);
		line-height: 20px;
		padding: 2px 10px;
		color: #000;
		font-weight: bold;
	}
}
</style>
