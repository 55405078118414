<template>
  <v-dialog
     
    max-width="800"
    v-model="modalVerMasInformacion"
    class="elevation-0 "
    dark
  >
    <v-card
      :style="`background-color:${$vuetify.theme.defaults.light.primary}`"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              :style="
                `position:absolute;z-index: 1; top:0;right:0;background-color:${$vuetify.theme.defaults.light.secondary}`
              "
              icon
              dark
              @click="close"
              circle
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div>
              {{ idvideo }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="text-align:center">
            <h3>
              <span v-if="contentVerMasInformacion.track_trailer_idvimeo"
                >Trailer:</span
              >
              {{ contentVerMasInformacion.trackTitle }}
            </h3>
            <div
              class="trailer"
              v-if="contentVerMasInformacion.track_trailer_idvimeo"
            >
              <!-- <div
                width="100%"
                :height="`${heightFrame}px`"
                id="videotrailer"
                ref="videotrailer"
                class="ma-0 pa-0 pt-md-0 pt-lg-0 pt-xl-0 pt-sm-2 pt-1"
                style="width:100%"
              ></div> -->
              <iframe
                :src="
                  `https://player.vimeo.com/video/${contentVerMasInformacion.track_trailer_idvimeo}`
                "
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                id="vimeoiframevermasinfo"
                ref="vimeoiframevermasinfo"
              ></iframe>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>Sobre:</h3>
            <p>
              {{ contentVerMasInformacion.track_short_description }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>¿Qué aprenderás?</h3>
            <p v-html="contentVerMasInformacion.track_object_learning"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-avatar size="200">
              <v-img
                :src="
                  contentVerMasInformacion.especialista_img ||
                    require('../../assets/especialistas/blanco.jpg')
                "
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col>
            <h3>{{ contentVerMasInformacion.especialista_nickname }}</h3>
            <h5 style="font-weight:normal;">
              {{ contentVerMasInformacion.especialista_bajada_corta }}
            </h5>
            <ol
              v-if="contentVerMasInformacion.especialista_bullet_point_logros"
            >
              <li
                :style="
                  `color:${$vuetify.theme.defaults.light.secondary};font-weight:bold;`
                "
                v-for="(item,
                index) of contentVerMasInformacion.especialista_bullet_point_logros
                  .split('*')
                  .filter(ele => ele)"
                :key="index"
              >
                <span style="color:#FFF;">{{ item }}</span>
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        v-if="contentVerMasInformacion.track_data_sheet"
        style="text-align:center; display:block;"
      >
        <v-btn
          :color="$vuetify.theme.defaults.light.secondary"
          :href="contentVerMasInformacion.track_data_sheet"
          target="_blank"
        >
          Ficha técnica
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      heightFrame: 600,
      videoplayer: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      try {
        // eslint-disable-next-line no-console
        console.log(this.$refs.vimeoiframevermasinfo)
        var iframe = this.$refs.vimeoiframevermasinfo
        this.videoplayer = new this.$Player(iframe)

        this.videoplayer.on('play', function () {
          // eslint-disable-next-line no-console
          console.log('Played the video')
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
    })
  },
  // beforeDestroy () {
  //   // eslint-disable-next-line no-console
  //   console.log(this.videoplayer)
  //   this.videoplayer.destroy()
  // },
  watch: {
    modalVerMasInformacion (newValue) {
      if (!newValue) {
        this.videoplayer.pause()
      }
      // else{
      //   this.videoplayer.
      // }
    }
  },
  computed: {
    ...mapState(['modalVerMasInformacion', 'contentVerMasInformacion']),

    idvideo () {
      // this.$nextTick().then(() => {
      //   // try {
      //   //   this.videoplayer.destroy()
      //   //   // eslint-disable-next-line no-empty
      //   // } catch (error) {}
      //   if (
      //     this.contentVerMasInformacion &&
      //     this.contentVerMasInformacion.track_trailer_idvimeo
      //   ) {
      //     // eslint-disable-next-line no-console
      //     console.log(this.contentVerMasInformacion.track_trailer_idvimeo)
      //     this.videoplayer = new this.$Player('videotrailer', {
      //       id: parseInt(this.contentVerMasInformacion.track_trailer_idvimeo),
      //       width: 500,
      //       height: 600
      //     })
      //   }
      // })

      return '' // this.contentVerMasInformacion.track_trailer_idvimeo
    }
  },
  methods: {
    ...mapMutations([
      'SET_MODALVERMASINFORMACION',
      'SET_CONTENTVERMASINFORMACION'
    ]),
    close () {
      // this.$emit('update:dialog', false)
      this.SET_MODALVERMASINFORMACION(false)
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
