var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0 mt-sm-0 mt-md-16 mb-5",class:{ 'overflow-y-hidden': _vm.$store.state.dialog }},[_c('v-col',[_c('v-row',{style:(("height:" + (_vm.isVideo ? _vm.altoVideo : _vm.altoBannerExplora) + "px;"))},[_c('v-col',{staticClass:"ma-0 pa-0 fill-height"},[_c('keep-alive',[_c('NiuBanner',{attrs:{"altoBannerExplora":_vm.altoBannerExplora,"team":_vm.$route.params.team},on:{"isVideo":function (isVideo) {
									this$1.isVideo = isVideo;
								}}})],1),_c('NiuDialogVideo')],1)],1),_c('v-row',[_c('v-col',[_c('h4',{staticStyle:{"color":"#000"}},[_vm._v("Continuar Viendo")])])],1),_c('v-row',[_c('v-col',{staticStyle:{"margin":"0","padding":"0"}},[(
							_vm.$vuetify.breakpoint.name != 'xs' &&
							_vm.$vuetify.breakpoint.name != 'sm'
						)?_c('CarrouselExplora',{attrs:{"trackTitle":'<strong>Módulos </strong>para Explorar',"trackCode":'explora',"trackId":0,"isPremium":1}}):_c('CarrouselExploraMovil',{attrs:{"trackTitle":'<strong>Continuar Viendo </strong>',"trackCode":'explora',"trackId":0,"isPremium":1}})],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-divider',{staticStyle:{"max-width":"20%"},attrs:{"color":"#4d1552"}}),_c('label',{staticClass:"txt-track-disponibles"},[_c('strong',[_c('v-icon',{staticClass:"font-50",attrs:{"dark":"","color":"#000"}},[_vm._v("mdi-bookmark-multiple ")]),_vm._v(" Tus Primeras ")],1),_vm._v(" 48 horas para el éxito ")])],1)],1)],1)],1),_c('v-row',_vm._l((_vm.loadCarruseles),function(loadCarrusel,llave){return _c('v-col',{key:'carrusel2_' + llave,staticClass:"niu-border-explora",class:loadCarrusel.isMasterClass == 1 ? 'pa-10' : 'pa-0',attrs:{"cols":loadCarrusel.isMasterClass == 1 ? '6' : '12'}},[_c('keep-alive',{key:'carrusel2_' + llave},[(
										loadCarrusel.isMasterClass == 0 &&
										_vm.$vuetify.breakpoint.name != 'xs' &&
										_vm.$vuetify.breakpoint.name != 'sm'
									)?_c('CarrouselModulos',{attrs:{"especialista":{
										img: loadCarrusel.especialista_img,
										nickname: loadCarrusel.especialista_nickname,
									},"infoTrack":{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										modulos: [],
										especialista_img: loadCarrusel.especialista_img,
										especialista_nickname: loadCarrusel.especialista_nickname,
										especialista_bajada_corta:
											loadCarrusel.especialista_bajada_corta,
										especialista_bullet_point_logros:
											loadCarrusel.especialista_bullet_point_logros,
										track_short_description:
											loadCarrusel.track_short_description,
										track_trailer_idvimeo: loadCarrusel.track_trailer_idvimeo,
										track_object_learning: loadCarrusel.track_object_learning,
										track_data_sheet: loadCarrusel.track_data_sheet,
									},"trackSesiones":loadCarrusel.trackSesiones,"trackDuracion":loadCarrusel.trackDuracion,"trackAddLearning":parseInt(loadCarrusel.track_is_add_learning)}}):(
										loadCarrusel.isMasterClass == 0 &&
										(_vm.$vuetify.breakpoint.name == 'xs' ||
											_vm.$vuetify.breakpoint.name == 'sm')
									)?_c('NewCarrusel',{attrs:{"infoTrack":{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										track_bullet_point: loadCarrusel.track_bullet_point,
										track_short_description:
											loadCarrusel.track_short_description,
										track_trailer_idvimeo: loadCarrusel.track_trailer_idvimeo,
										track_object_learning: loadCarrusel.track_object_learning,
										track_data_sheet: loadCarrusel.track_data_sheet,

										modulos: [],
									},"trackSesiones":loadCarrusel.trackSesiones,"trackDuracion":loadCarrusel.trackDuracion,"trackAddLearning":parseInt(loadCarrusel.track_is_add_learning)}}):(loadCarrusel.isMasterClass == 1)?_c('CarruselMasterClass',{attrs:{"infoTrack":{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										track_type_curso: loadCarrusel.track_type_curso,
										modulos: [],
									},"trackSesiones":loadCarrusel.trackSesiones,"track_id_video":loadCarrusel.track_id_video,"track_is_content":loadCarrusel.track_is_content,"track_description":loadCarrusel.track_description},on:{"OpenDialogModuloPremium":_vm.OpenDialogModuloPremium}}):_vm._e()],1)],1)}),1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-divider',{staticStyle:{"max-width":"20%"},attrs:{"color":"#4d1552"}}),_c('label',{staticClass:"txt-track-disponibles"},[_c('strong',[_c('v-icon',{staticClass:"font-50",attrs:{"dark":"","color":"#000"}},[_vm._v("mdi-bookmark-multiple ")]),_vm._v(" Contenido ")],1),_vm._v(" complementario ")])],1)],1)],1)],1),_c('v-row',_vm._l((_vm.loadMasterClass),function(loadCarrusel,llave){return _c('v-col',{key:'carrusel2_' + llave,staticClass:"niu-border-explora",class:loadCarrusel.isMasterClass == 1 ? 'pa-3' : 'pa-0',attrs:{"cols":"12","md":loadCarrusel.isMasterClass == 1 ? '6' : '12'}},[_c('keep-alive',{key:'carrusel2_' + llave},[(
										loadCarrusel.isMasterClass == 0 &&
										_vm.$vuetify.breakpoint.name != 'xs' &&
										_vm.$vuetify.breakpoint.name != 'sm'
									)?_c('CarrouselModulos',{attrs:{"especialista":{
										img: loadCarrusel.especialista_img,
										nickname: loadCarrusel.especialista_nickname,
									},"infoTrack":{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										modulos: [],
										especialista_img: loadCarrusel.especialista_img,
										especialista_nickname: loadCarrusel.especialista_nickname,
										especialista_bajada_corta:
											loadCarrusel.especialista_bajada_corta,
										especialista_bullet_point_logros:
											loadCarrusel.especialista_bullet_point_logros,
										track_short_description:
											loadCarrusel.track_short_description,
										track_trailer_idvimeo: loadCarrusel.track_trailer_idvimeo,
										track_object_learning: loadCarrusel.track_object_learning,
										track_data_sheet: loadCarrusel.track_data_sheet,
									},"trackSesiones":loadCarrusel.trackSesiones,"trackDuracion":loadCarrusel.trackDuracion,"trackAddLearning":parseInt(loadCarrusel.track_is_add_learning)}}):(
										loadCarrusel.isMasterClass == 0 &&
										(_vm.$vuetify.breakpoint.name == 'xs' ||
											_vm.$vuetify.breakpoint.name == 'sm')
									)?_c('NewCarrusel',{attrs:{"infoTrack":{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										track_bullet_point: loadCarrusel.track_bullet_point,
										track_short_description:
											loadCarrusel.track_short_description,
										track_trailer_idvimeo: loadCarrusel.track_trailer_idvimeo,
										track_object_learning: loadCarrusel.track_object_learning,
										track_data_sheet: loadCarrusel.track_data_sheet,

										modulos: [],
									},"trackSesiones":loadCarrusel.trackSesiones,"trackDuracion":loadCarrusel.trackDuracion,"trackAddLearning":parseInt(loadCarrusel.track_is_add_learning)}}):(loadCarrusel.isMasterClass == 1)?_c('CarruselMasterClass',{attrs:{"is_team":true,"infoTrack":{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										track_type_curso: loadCarrusel.track_type_curso,
										modulos: [],
									},"trackSesiones":loadCarrusel.trackSesiones,"track_id_video":loadCarrusel.track_id_video,"track_is_content":loadCarrusel.track_is_content,"track_description":loadCarrusel.track_description},on:{"OpenDialogModuloPremium":_vm.OpenDialogModuloPremium}}):_vm._e()],1)],1)}),1),_c('NiuDialogModuloPremium',{attrs:{"dialogModuloPremium":_vm.dialogModuloPremium},on:{"CloseDialogModuloPremium":_vm.CloseDialogModuloPremium}}),_c('ModalVerMasInforamcion')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }