<template>
	<v-sheet class="niu-width-track" light elevation="0">
		<v-row>
			<v-col :id="infoTrack.trackId" class="text-track-title px-3 pl-2 mx-5">
				<label>{{ `${infoTrack.trackTitle} ` }} </label>
				<v-avatar :size="sizeLogoTrack">
					<v-img
						:size="sizeLogoTrack"
						:src="
							infoTrack.isPremium === 0
								? require('../../assets/weblogo_n.png')
								: require('../../assets/logo_premium_150x150.png')
						"
					>
					</v-img>
				</v-avatar>
				<v-avatar
					:size="sizeLogoTrack"
					v-if="infoTrack.trackMobileFriendly === 1"
					color="primary"
				>
					<v-img
						:size="sizeLogoTrack"
						:src="require('../../assets/iconos/moviles.png')"
					>
					</v-img>
				</v-avatar>
				<span v-if="infoTrack.isPersonalizadoNiufleex == 1">
					Personalizado
				</span>

				<div class="niu-iconos">
					<label>
						<v-icon style="color: #717171">mdi-ballot-outline</v-icon>
						Módulos:
						{{ modulos.length }}
					</label>
					<label v-if="trackSesiones != 0">
						<v-icon style="color: #717171">mdi-file-document</v-icon>
						Sesiones: {{ trackSesiones || "00" }}
					</label>
					<label v-if="trackDuracion != 0">
						<v-icon style="color: #717171">mdi-clock-time-three-outline</v-icon>
						Duración: {{ trackDuracion || "000" }} min</label
					>
				</div>
				<template
					v-if="
						infoTrack.trackProgreso == 0 &&
						trackAddLearning == 1 &&
						$router.currentRoute.name != 'navega' &&
						$router.currentRoute.name != 'teams' &&
						$router.currentRoute.name != 'explora-free'
					"
				>
					<v-chip
						style="
							background-color: #ff8b02 !important;
							color: white !important;
						"
						@click="openDialog(infoTrack.trackProgreso)"
						v-if="modulos.length >= 4 && infoTrack.modulos.length == 0"
					>
						<v-icon color="white"> mdi-plus </v-icon>
						<label class="d-flex d-sm-none"> Agregar </label>
						<label class="d-none d-sm-flex">
							Agregar el Track a Mis rutas de aprendizaje
						</label>
						<v-icon color="white"> mdi-transit-connection-horizontal </v-icon>
					</v-chip>
				</template>
				<template
					v-else-if="
						infoTrack.trackProgreso == 1 &&
						$router.currentRoute.name != 'navega'
					"
				>
					<v-chip style="background-color: #ff8b02 !important">
						<label> Ruta agregada </label>
					</v-chip>
				</template>
			</v-col>
		</v-row>
		<div class="list">
			<div class="list-content">
				<div
					class="list-item"
					v-for="(modulo, index) in modulos"
					:key="`modulo_${
						$router.currentRoute.name
					}_${infoTrack.trackCode.substr(0, 5)}_${
						modulo.agrupaModuloId
					}_slide${index}`"
				>
					<v-hover v-slot:default="{ hover }">
						<v-card
							:elevation="hover ? 15 : 1"
							class="niu-card-main niu-background niu-text"
							:class="{
								'niu-dark': infoTrack.isPremium === 0 ? false : true,
								'niu-light': infoTrack.isPremium === 0 ? true : false,
							}"
						>
							<v-row class="pa-0 ma-0" align="center" justify="center">
								<v-col class="pa-0 ma-0">
									<v-img
										height="180px"
										:aspect-ratio="17 / 9"
										:src="loadImage(modulo.moduloImg, modulo.comming_soon)"
										lazy-src="../../assets/client-loader.gif"
										style="position: relative !important"
										class="rounded-lg"
									>
										<div
											style="
												position: absolute;
												top: 45%;
												right: 45%;
												z-index: 1;
											"
										>
											<v-btn
												icon
												class="niu-play-modulo"
												@click="activeModulo(modulo)"
												style="
													text-shadow: 1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc,
														0px -1px #ccc;
												"
											>
												<v-icon size="70px"> mdi-play-circle </v-icon>
											</v-btn>
										</div>
										<div style="position: absolute; top: 0px; left: 0px">
											<v-chip
												class="ma-2"
												:class="colorFondoModuloClass(modulo)"
											>
												Módulo {{ modulo.moduloNumber }}
											</v-chip>
										</div>
									</v-img>
								</v-col>
							</v-row>
							<v-row align="end" class="pa-2 ma-0">
								<v-col class="ma-0 pa-0 niu-text-carrusel">
									<div
										style="position: absolute; top: -35px; right: 20px"
										v-if="false"
									>
										<v-avatar :class="colorFondoModuloClass(modulo)" size="42">
											<span class="headline">{{ modulo.moduloNumber }}</span>
										</v-avatar>
									</div>
									<span
										v-html="modulo.moduloTitle"
										class="niu-carousel-texto-modulo"
									></span>
									<p
										style="height: 50px; padding: 15px 0 0 0"
										class="d-none d-sm-flex"
									>
										<v-icon
											class="niu-text size-7-rem"
											:class="{
												...iconModuloClass(modulo, hover),
												'niu-dark': infoTrack.isPremium === 0 ? false : true,
												'niu-light': infoTrack.isPremium === 0 ? true : false,
											}"
										>
											mdi-account
										</v-icon>
										<label class="niu-text"
											><strong>Especialista: </strong>
											{{ modulo.moduloEspecialista }}</label
										>
									</p>
								</v-col>
							</v-row>
							<v-sheet
								style="position: absolute; bottom: 0px; width: 90%; left: 5%"
								align="center"
								justify="end"
								class="transparent"
								light
							>
								<small
									class="niu-text-bold niu-text"
									:class="{
										...iconModuloClass(modulo, hover),
										'niu-dark': infoTrack.isPremium === 0 ? false : true,
										'niu-light': infoTrack.isPremium === 0 ? true : false,
									}"
									>Sesiones: {{ modulo.moduloNroSesiones }}</small
								>
								<small
									class="niu-text-bold ml-4 niu-text"
									:class="{
										...iconModuloClass(modulo, hover),
										'niu-dark': infoTrack.isPremium === 0 ? false : true,
										'niu-light': infoTrack.isPremium === 0 ? true : false,
									}"
									>Tiempo: {{ modulo.moduloDuracion }} minutos</small
								>
							</v-sheet>
							<div
								class="niu-barra"
								:class="barraInferiorModuloCarrusel(modulo, hover)"
							></div>
							<!-- <div class="niu-numero-modulo">
              {{ modulo.moduloNumber }}
            </div> -->
						</v-card>
					</v-hover>
				</div>
			</div>
		</div>
	</v-sheet>
</template>
<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";
export default {
	props: {
		isVitrina: {
			type: Boolean,
			default: false,
		},
		infoTrack: {
			type: Object,
			default: () => {
				return {
					trackTitle: "",
					trackCode: "",
					trackId: null,
					trackAreaconocimientoCode: "",
					trackProgreso: null,
					modulos: [],
					isPremium: 0,
				};
			},
		},

		trackSesiones: {
			type: Number,
			default: 0,
		},
		trackDuracion: {
			type: Number,
			default: 0,
		},
		trackAddLearning: {
			type: Number,
			default: 0,
		},
	},

	computed: {
		...mapState(["KnowledgeAreas2"]),
		sizeLogoTrack() {
			// 23 / 8
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 30;
				case "sm":
					return 30;
				case "md":
					return 40;
				case "lg":
					return 40;
				case "xl":
					return 40;
			}
		},
	},
	// updated () {
	//   // eslint-disable-next-line no-console
	//   console.log(this.infoTrack)
	// },
	// created () {
	//   // eslint-disable-next-line no-console
	//   // console.log(this.trackCode)
	//   // eslint-disable-next-line no-console
	//   console.log(this.infoTrack)
	// },
	async mounted() {
		if (this.infoTrack.modulos.length == 0) {
			// eslint-disable-next-line no-console
			// console.log(this.infoTrack)

			this.modulos = (
				await this.$http.get(`/Modulos/Trackid/${this.infoTrack.trackId}`)
			).data;
			// if (this.videonumber != null) {
			//   this.model = this.videonumber
			// }

			// eslint-disable-next-line no-console
			// console.log(this.modulos)
			// eslint-disable-next-line no-console
		} else {
			this.modulos = this.infoTrack.modulos;
		}
	},
	data: () => ({
		model: {},
		modulos: [],
		videos: [],
		createRouteDialog: false,

		cart: null,
		degreeTitle: "",
		txtdata: "",
		openCreateDialog: false,
	}),
	methods: {
		...mapMutations("carruseles", ["SET_MODULO"]),
		...mapMutations(["SET_ALERT", "SET_ADDRUTATRACK", "SET_INFOTRACK"]),
		loadImage(moduloImg, comming_soon) {
			let img = moduloImg.split(".");
			img.pop();
			// eslint-disable-next-line
			// console.log(comming_soon)
			try {
				// let img =
				//   '../../assets/' +
				//   (comming_soon == 1 ? `${img.join('')}_pronto.jpg` : moduloImg)

				// eslint-disable-next-line no-console
				// console.log(img)
				return require("../../assets/" +
					(comming_soon == 1 ? `${img.join("")}_pronto.jpg` : moduloImg));
			} catch (error) {
				return require("../../assets/proximamente.png");
			}
		},
		closeDialog() {
			this.createRouteDialog = false;

			this.SET_ADDRUTATRACK({
				modulos: [],
				createRouteDialog: false,
				trackTitle: "",
			});
		},

		openDialog() {
			if (this.infoTrack.trackProgreso == 0) {
				// eslint-disable-next-line no-console
				console.log("openDialog");
				this.SET_ADDRUTATRACK({
					modulos: this.modulos,
					createRouteDialog: true,
					trackTitle: this.infoTrack.trackTitle,
				});

				// EventBus.$emit('addRutas', {
				//   modulos: this.modulos,
				//   createRouteDialog: true,
				//   trackTitle: this.trackTitle
				// })
				// this.createRouteDialog = true
			}
		},
		activeModulo(moduloSeleccionado) {
			if (this.isVitrina) {
				this.$router.push("/compraahora");
			} else {
				if (moduloSeleccionado.comming_soon == 0) {
					if (this.videos.lenght > 0) {
						document.documentElement.classList.add("niu-overflow-hidden");
						this.$store.commit("dialog", {
							dialog: true,
							modulo: moduloSeleccionado,
							numberVideo: 0,
						});
					}

					this.SET_MODULO(moduloSeleccionado);
					this.SET_INFOTRACK(this.infoTrack);

					let url = `/track/${moduloSeleccionado.trackCode}#mod-${moduloSeleccionado.moduloId}`;
					// eslint-disable-next-line no-console
					console.log(url);
					this.$router.push(url);
					// eslint-disable-next-line no-console
					console.log(this.$router, this.$route, this.$routes);
					// this.$router.go(this.)

					// this.$router.push(`/modulo/${this.infoTrack.trackCode}/${moduloSeleccionado.moduloCode}`)
				} else {
					this.SET_ALERT({
						message: `
          <strong style="color:black;">
          Contenido aún no disponible
          </strong>`,
						type: "warning",
						snackbar: true,
					});
				}
			}
		},
		fondoModuloClass(modulo, hover) {
			return {
				"niu-background-gradient-emprendimientonegocio":
					modulo.areaconocimientoCode == "emprendimientonegocio" && hover,
				"niu-background-gradient-liderazgo":
					modulo.areaconocimientoCode == "liderazgo" && hover,
				"niu-background-gradient-comunicacion":
					modulo.areaconocimientoCode == "comunicacion" && hover,
				"niu-background-gradient-desarrollofisico":
					modulo.areaconocimientoCode == "desarrollofisico" && hover,
				"niu-background-gradient-desarrollopersonal":
					modulo.areaconocimientoCode == "desarrollopersonal" && hover,
				"niu-background-gradient-conocimientotecnico":
					modulo.areaconocimientoCode == "conocimientotecnico" && hover,
			};
		},
		iconModuloClass(modulo, hover) {
			return {
				"niu-text-emprendimientonegocio":
					modulo.areaconocimientoCode == "emprendimientonegocio" && hover,
				"niu-text-liderazgo":
					modulo.areaconocimientoCode == "liderazgo" && hover,
				"niu-text-comunicacion":
					modulo.areaconocimientoCode == "comunicacion" && hover,
				"niu-text-desarrollofisico":
					modulo.areaconocimientoCode == "desarrollofisico" && hover,
				"niu-text-desarrollopersonal":
					modulo.areaconocimientoCode == "desarrollopersonal" && hover,
				"niu-text-conocimientotecnico":
					modulo.areaconocimientoCode == "conocimientotecnico" && hover,
			};
		},
		iconTrackClass(areaconocimientoCode) {
			return {
				"niu-background-solid-emprendimientonegocio":
					areaconocimientoCode == "emprendimientonegocio",
				"niu-background-solid-liderazgo": areaconocimientoCode == "liderazgo",
				"niu-background-solid-comunicacion":
					areaconocimientoCode == "comunicacion",
				"niu-background-solid-desarrollofisico":
					areaconocimientoCode == "desarrollofisico",
				"niu-background-solid-desarrollopersonal":
					areaconocimientoCode == "desarrollopersonal",
				"niu-background-solid-conocimientotecnico":
					areaconocimientoCode == "conocimientotecnico",
			};
		},
		barraInferiorModuloCarrusel(modulo, hover) {
			return {
				"niu-background-solid-emprendimientonegocio":
					modulo.areaconocimientoCode == "emprendimientonegocio" && hover,
				"niu-background-solid-liderazgo":
					modulo.areaconocimientoCode == "liderazgo" && hover,
				"niu-background-solid-comunicacion":
					modulo.areaconocimientoCode == "comunicacion" && hover,
				"niu-background-solid-desarrollofisico":
					modulo.areaconocimientoCode == "desarrollofisico" && hover,
				"niu-background-solid-desarrollopersonal":
					modulo.areaconocimientoCode == "desarrollopersonal" && hover,
				"niu-background-solid-conocimientotecnico":
					modulo.areaconocimientoCode == "conocimientotecnico" && hover,
			};
		},
		colorFondoModuloClass(modulo) {
			return {
				"niu-background-solid-emprendimientonegocio":
					modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-solid-liderazgo":
					modulo.areaconocimientoCode == "liderazgo",
				"niu-background-solid-comunicacion":
					modulo.areaconocimientoCode == "comunicacion",
				"niu-background-solid-desarrollofisico":
					modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-solid-desarrollopersonal":
					modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-solid-conocimientotecnico":
					modulo.areaconocimientoCode == "conocimientotecnico",
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.niu-card-main {
	height: 265px;
}
$fondo: white;
$fondo_card: black;
.niu-dark.niu-background {
	background-color: black !important;
}
.niu-dark.niu-text {
	color: white !important;
}
.niu-light.niu-background {
	background-color: #f5f5f5 !important;
}
.niu-light.niu-text {
	color: black !important;
}
// medida de 0.2rem por unidad
.niu-text.size-7-rem {
	font-size: 1.4rem;
}
.niu-back-dialog-0 {
	background-color: #a52882 !important;
}
.niu-back-dialog-1 {
	background-color: #a50b7a !important;
}
.niu-back-dialog-2 {
	background-color: #590d53 !important;
}
.niu-iconos {
	font-size: 1.2rem;
	font-weight: normal;
	color: #717171;
}
.niu-label {
	display: inline-block;
	border-radius: 10px;
	line-height: 5px;
	padding: 10px;
	position: absolute;
	top: 0;
}
.theme--dark.v-btn.v-btn--icon.niu-btn-disabled {
	color: transparent !important;
}
.v-responsive.v-image {
	border-radius: 16px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0;
}
.texto_modulo {
	transform: rotate(-45deg);
	display: block;
	position: absolute;
	top: 40px;
	left: 10px;
	font-size: 1.5rem;
	font-weight: bold;
	text-shadow: 1px 1px #000;
}
.box_vineta {
	width: 100%;
	height: 100%;
	border: 0px solid transparent;
	background: linear-gradient(
		-45deg,
		transparent 0,
		transparent 70%,
		rgba(0, 0, 0, 0.75) 0,
		rgba(0, 0, 0, 0.75) 30%
	);
}

.niu-rounded-4 {
	border-radius: 4px;
}
.niu-rounded-tl-4 {
	border-top-left-radius: 4px;
}
.niu-rounded-tr-4 {
	border-top-right-radius: 4px;
}
.niu-rounded-br-4 {
	border-bottom-right-radius: 4px;
}
.niu-rounded-bl-4 {
	border-bottom-left-radius: 4px;
}
.niu-rounded-b-4 {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.niu-rounded-t-4 {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.niu-title-carousel {
	color: #fff;
	text-shadow: 1px 1px 1px #000;
}
.niu-position-relative {
	position: relative;
}
.niu-overflow-hidden {
	overflow: hidden;
}
.niu-text-carrusel {
	min-height: 100px;
}

$ancho: 300px;
$alto: 430px;
$altoSlid: 750px;

.text-track-title {
	color: black;
	font-size: 30px;
	display: inline-block;
	width: 100%;
	position: relative;
	font-weight: bold;
}

// .niu-width-track {
//   width: 96%;
//   left: 2%;
//   height: 535px;
// }
// .niu-card-main {
//   width: $ancho;
//   height: 320px;
//   margin-right: 20px;
//   margin-left: 20px;
//   margin-bottom: 15px;
//   border-radius: 16px !important;
//   position: relative;
// }
.niu-iconos {
	font-size: 1rem;
	font-weight: normal;
	color: #717171;
}

.niu-carousel-texto-modulo {
	text-align: left;
	/* bottom: 0; */
	/* height: 60px; */
	/* padding: 4px; */
	font-size: 1.1rem;
	font-weight: 700;
	word-break: break-word;
	text-transform: none;
	/* margin-bottom: 40px; */
	display: block;
	height: 65px;
}
.niu-carousel-texto-modulo-xs {
	text-align: left;
	/* bottom: 0; */
	/* height: 60px; */
	/* padding: 4px; */
	font-size: 1.1rem;
	font-weight: 700;
	word-break: break-word;
	text-transform: none;
	/* margin-bottom: 40px; */
	display: block;
}
.cicle-ball-area {
	width: 10px;
	height: 10px;
	background: white;
	display: inline-block;
	border-radius: 50%;
	margin: 0 4px 0 0;
}
.niu-hover {
	border-radius: 8px;
	border: 3px solid #444;
	border-radius: 8px;
}
.niu-border-emprendimientonegocio-dark {
	border-color: #ffae04 !important;
}
.niu-border-emprendimientonegocio-light {
	border-color: #ffe800 !important;
}
.niu-border-liderazgo-dark {
	border-color: #f64600 !important;
}
.niu-border-liderazgo-light {
	border-color: #f39d00 !important;
}
.niu-border-desarrollofisico-dark {
	border-color: #770005 !important;
}
.niu-border-desarrollofisico-light {
	border-color: #f40000 !important;
}
.niu-border-desarrollopersonal-dark {
	border-color: #2d6b00 !important;
}
.niu-border-desarrollopersonal-light {
	border-color: #2ae82d !important;
}
.niu-border-conocimientotecnico-dark {
	border-color: #11b29c !important;
}
.niu-border-conocimientotecnico-light {
	border-color: #3effe8 !important;
}
.niu-border-comunicacion-dark {
	border-color: #354cf9 !important;
}
.niu-border-comunicacion-light {
	border-color: #0540f2 !important;
}

.niu-barra {
	width: 70%;
	height: 5px;
	background-color: black;
	position: absolute;
	bottom: 0;
	z-index: 20;
	border-radius: 2px;
	left: 15%;
}
// .v-icon.notranslate.mdi.mdi-chevron-right,
// .v-icon.notranslate.mdi.mdi-chevron-left {
//   font-size: 70px;
//   border: 1px solid;
//   border-radius: 50%;
// }
.niu-background-gradient-emprendimientonegocio {
	background: rgb(255, 174, 4);
	background: linear-gradient(45deg, rgba(255, 174, 4, 1) 0%, #ffb92e 100%);
}
.niu-background-gradient-liderazgo {
	background: rgb(246, 70, 0);
	background: linear-gradient(45deg, rgba(246, 70, 0, 1) 0%, #c10000 100%);
}
.niu-background-gradient-desarrollofisico {
	background: #fc3a72;
	background: linear-gradient(
		45deg,
		rgba(119, 0, 5, 1) 0%,
		rgba(244, 0, 0, 1) 100%
	);
}
.niu-background-gradient-desarrollopersonal {
	background: #8ac445;
	background: linear-gradient(
		45deg,
		rgba(45, 107, 0, 1) 0%,
		rgba(42, 232, 45, 1) 100%
	);
}
.niu-background-gradient-conocimientotecnico {
	background: rgb(17, 178, 156);
	background: linear-gradient(45deg, #40ccb1 0%, rgba(62, 255, 232, 1) 100%);
}
.niu-background-gradient-comunicacion {
	background: rgb(3, 0, 42);
	background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
}

.niu-background-gradient-emprendimientonegocio {
	background: #ffb92e;
	background: linear-gradient(45deg, #ffb92e 0%, rgba(255, 174, 4, 1) 100%);
}
.niu-background-gradient-liderazgo {
	background: #c10000;
	background: linear-gradient(45deg, #c10000 0%, rgba(246, 70, 0, 1) 100%);
}
.niu-background-gradient-desarrollofisico {
	background: rgba(244, 0, 0, 1);
	background: linear-gradient(
		45deg,
		rgba(244, 0, 0, 1) 0%,
		rgba(119, 0, 5, 1) 100%
	);
}
.niu-background-gradient-desarrollopersonal {
	background: rgba(42, 232, 45, 1);
	background: linear-gradient(
		45deg,
		rgba(42, 232, 45, 1) 0%,
		rgba(45, 107, 0, 1) 100%
	);
}
.niu-background-gradient-conocimientotecnico {
	background: rgba(62, 255, 232, 1);
	background: linear-gradient(45deg, rgba(62, 255, 232, 1) 0%, #40ccb1 100%);
}
.niu-background-gradient-comunicacion {
	background: rgb(3, 0, 42);
	background: linear-gradient(45deg, #354cf9 0%, #0540f2 100%);
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
	font-size: 36px !important;
	color: #ababab !important;
}

$emprendimientonegocio: #ffb92e;
$liderazgo: #c10000;
$desarrollofisico: #fc3a72;
$desarrollopersonal: #8ac445;
$conocimientotecnico: #40ccb1;
$comunicacion: #354cf9;

.niu-text {
	font-size: 0.9rem;
}

.niu-text-bold {
	font-weight: bold;
	font-size: 0.9rem;
}

.niu-text-black {
	color: rgba(0, 0, 0, 1) !important;
}
.niu-dark.niu-text.niu-text-emprendimientonegocio,
.niu-light.niu-text.niu-text-emprendimientonegocio {
	color: $emprendimientonegocio !important;
}
.niu-dark.niu-text.niu-text-liderazgo,
.niu-light.niu-text.niu-text-liderazgo {
	color: $liderazgo !important;
}
.niu-dark.niu-text.niu-text-desarrollofisico,
.niu-light.niu-text.niu-text-desarrollofisico {
	color: $desarrollofisico !important;
}
.niu-dark.niu-text.niu-text-desarrollopersonal,
.niu-light.niu-text.niu-text-desarrollopersonal {
	color: $desarrollopersonal !important;
}
.niu-dark.niu-text.niu-text-conocimientotecnico,
.niu-light.niu-text.niu-text-conocimientotecnico {
	color: $conocimientotecnico !important;
}
.niu-dark.niu-text.niu-text-comunicacion,
.niu-light.niu-text.niu-text-comunicacion {
	color: $comunicacion !important;
}

.v-chip.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
	background: $emprendimientonegocio !important;
}
.v-chip.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
	background: $liderazgo !important;
}
.v-chip.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
	background: $desarrollofisico !important;
}
.v-chip.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
	background: $desarrollopersonal !important;
}
.v-chip.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
	background: $conocimientotecnico !important;
}
.v-chip.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
	background: $comunicacion !important;
}
.v-chip.niu-background-solid-emprendimientonegocio,
.niu-background-solid-emprendimientonegocio {
	color: white !important;
}
.v-chip.niu-background-solid-liderazgo,
.niu-background-solid-liderazgo {
	color: white !important;
}
.v-chip.niu-background-solid-desarrollofisico,
.niu-background-solid-desarrollofisico {
	color: white !important;
}
.v-chip.niu-background-solid-desarrollopersonal,
.niu-background-solid-desarrollopersonal {
	color: white !important;
}
.v-chip.niu-background-solid-conocimientotecnico,
.niu-background-solid-conocimientotecnico {
	color: white !important;
}
.v-chip.niu-background-solid-comunicacion,
.niu-background-solid-comunicacion {
	color: white !important;
}

.v-application p {
	margin-bottom: 4px !important;
}
.niu-play-modulo {
	color: white !important;
}
.niu-play-modulo:hover {
	color: #ddd !important;
}
.button-secondary::before {
	display: block;
	/* border: 1px solid green; */
	content: "";
	width: 25px;
	height: 25px;
	margin: auto;
	margin-bottom: 5px;
}

.icon-plus::before {
	background: url("../../assets/images/icons/plus.svg");
}

.icon-play::before {
	background: url("../../assets/images/icons/play.svg");
}

.icon-info::before {
	background: url("../../assets/images/icons/info.svg");
}

.list-content {
	/* border: 1px solid red; */
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	height: 280px;
}
/* width */
.list-content::-webkit-scrollbar {
	width: 5px !important;
}

/* Track */
.list-content::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(17, 17, 17);
	border-radius: 5px;
	width: 5px !important;
}

/* Handle */
.list-content::-webkit-scrollbar-thumb {
	background: rgb(10, 0, 12);
	border-radius: 5px;
	width: 5px !important;
}

/* Handle on hover */
.list-content::-webkit-scrollbar-thumb:hover {
}
.list-item {
	display: inline-block;
	margin-left: 10px;
	height: 260px;
}
.list-item:first-child {
	margin: 0;
}

.list {
	/* border: 1px solid green; */
	padding: 0 1em;
	height: 260px;
}

main {
	/* border: 1px solid red; */
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.niu-width-track {
	height: 440px;
}
@media (max-width: 600px) and (min-width: 400px) {
	.text-track-title {
		font-size: 18px;
	}
}

@media (max-width: 400px) and (min-width: 300px) {
	.text-track-title {
		font-size: 18px;
	}
}
</style>
