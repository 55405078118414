<template>
	<v-row
		:class="{ 'overflow-y-hidden': $store.state.dialog }"
		class="mt-0 mt-sm-0 mt-md-16 mb-5"
	>
		<v-col>
			<v-row
				:style="`height:
                  ${isVideo ? altoVideo : altoBannerExplora}px;`"
			>
				<v-col class="ma-0 pa-0 fill-height">
					<!-- <div style="position:fixed;
            width:50px;
            height:80px;
            z-index:10;
            color:white;
            text-shadow: 1px 1px 1px black,-1px -1px 1px black,1px -1px 1px black;">
            {{ $vuetify.breakpoint.name }}
          </div> -->
					<keep-alive>
						<NiuBanner
							:altoBannerExplora="altoBannerExplora"
							@isVideo="
								(isVideo) => {
									this.isVideo = isVideo;
								}
							"
							:isPremium="true"
						></NiuBanner>
					</keep-alive>
					<NiuDialogVideo></NiuDialogVideo>
				</v-col>
			</v-row>
			<v-row align="center">
				<v-col align="center">
					<mensaje-bajada
						:mensaje="`Explora es un espacio virtual de descubrimiento y visualización de los diferentes programas y planes de contenido`"
					></mensaje-bajada>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<h4 style="color: #000">Continuar Viendo</h4>
				</v-col>
			</v-row>
			<v-row>
				<v-col style="margin: 0; padding: 0">
					<CarrouselExplora
						v-if="
							$vuetify.breakpoint.name != 'xs' &&
							$vuetify.breakpoint.name != 'sm'
						"
						:trackTitle="'<strong>Módulos </strong>para Explorar'"
						:trackCode="'explora'"
						:trackId="0"
						:isPremium="1"
					></CarrouselExplora>
					<CarrouselExploraMovil
						v-else
						:trackTitle="'<strong>Módulos </strong>para Explorar'"
						:trackCode="'explora'"
						:trackId="0"
						:isPremium="1"
					></CarrouselExploraMovil>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-row>
						<v-col>
							<v-row>
								<v-col>
									<v-divider color="#4d1552" style="max-width: 20%"></v-divider>
									<label class="txt-track-disponibles">
										<strong>
											<v-icon dark color="#000" class="font-50"
												>mdi-bookmark-multiple
											</v-icon>
											Cursos
										</strong>
										disponibles para ti
									</label>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row
						justify="center"
						style="background-color: rgb(30 30 30 / 95%)"
						class="my-5"
					>
						<v-col cols="10">
							<v-btn
								class="ma-1"
								:color="
									showfilter.filter((ele) => area.name == ele).length > 0
										? area.color.primary
										: '#00000070'
								"
								@click="filterTracks(area.name)"
								v-for="area of filters"
								:key="area.description + '-' + area.name"
								>{{ area.description }}</v-btn
							>
							<v-btn
								class="ml-3"
								:color="showmasterclass ? '#000' : '#00000070'"
								@click="filterMasterClass()"
								>Cursos Complementarios</v-btn
							>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							v-for="(loadCarrusel, llave) of loadCarruseles"
							:key="'carrusel2_' + llave"
							v-show="
								(showfilter.filter(
									(ele) => loadCarrusel.trackAreaconocimientoCode == ele
								).length > 0 &&
									loadCarrusel.isMasterClass == 0) ||
								(showmasterclass == true && loadCarrusel.isMasterClass == 1)
							"
							:cols="loadCarrusel.isMasterClass == 1 ? '6' : '12'"
							:class="loadCarrusel.isMasterClass == 1 ? 'pa-10' : 'pa-0'"
							class="niu-border-explora"
						>
							<keep-alive :key="'carrusel2_' + llave">
								<CarrouselModulos
									v-if="
										loadCarrusel.isMasterClass == 0 &&
										$vuetify.breakpoint.name != 'xs' &&
										$vuetify.breakpoint.name != 'sm'
									"
									:especialista="{
										img: loadCarrusel.especialista_img,
										nickname: loadCarrusel.especialista_nickname,
									}"
									:infoTrack="{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										modulos: [],
										especialista_img: loadCarrusel.especialista_img,
										especialista_nickname: loadCarrusel.especialista_nickname,
										especialista_bajada_corta:
											loadCarrusel.especialista_bajada_corta,
										especialista_bullet_point_logros:
											loadCarrusel.especialista_bullet_point_logros,
										track_short_description:
											loadCarrusel.track_short_description,
										track_trailer_idvimeo: loadCarrusel.track_trailer_idvimeo,
										track_object_learning: loadCarrusel.track_object_learning,
										track_data_sheet: loadCarrusel.track_data_sheet,
									}"
									:trackSesiones="loadCarrusel.trackSesiones"
									:trackDuracion="loadCarrusel.trackDuracion"
									:trackAddLearning="
										parseInt(loadCarrusel.track_is_add_learning)
									"
								></CarrouselModulos>
								<NewCarrusel
									v-else-if="
										loadCarrusel.isMasterClass == 0 &&
										($vuetify.breakpoint.name == 'xs' ||
											$vuetify.breakpoint.name == 'sm')
									"
									:infoTrack="{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										track_bullet_point: loadCarrusel.track_bullet_point,
										track_short_description:
											loadCarrusel.track_short_description,
										track_trailer_idvimeo: loadCarrusel.track_trailer_idvimeo,
										track_object_learning: loadCarrusel.track_object_learning,
										track_data_sheet: loadCarrusel.track_data_sheet,
										modulos: [],
									}"
									:trackSesiones="loadCarrusel.trackSesiones"
									:trackDuracion="loadCarrusel.trackDuracion"
									:trackAddLearning="
										parseInt(loadCarrusel.track_is_add_learning)
									"
								></NewCarrusel>
								<CarruselMasterClass
									v-else-if="loadCarrusel.isMasterClass == 1"
									:infoTrack="{
										trackTitle: loadCarrusel.trackTitle,
										trackCode: loadCarrusel.trackCode,
										trackId: loadCarrusel.trackId,
										trackAreaconocimientoCode:
											loadCarrusel.trackAreaconocimientoCode,
										trackProgreso: loadCarrusel.trackProgreso,
										isPremium: loadCarrusel.isPremium,
										trackMobileFriendly: loadCarrusel.track_is_mobile_friendly,
										isPersonalizadoNiufleex:
											loadCarrusel.is_personalizado_niufleex,
										modulos: [],
									}"
									:trackSesiones="loadCarrusel.trackSesiones"
									:track_id_video="loadCarrusel.track_id_video"
									:track_is_content="loadCarrusel.track_is_content"
									:track_description="loadCarrusel.track_description"
									@OpenDialogModuloPremium="OpenDialogModuloPremium"
								></CarruselMasterClass>
							</keep-alive>
						</v-col>
					</v-row>
					<!-- <v-row justify="center" v-if="!stopNextPage">
            <v-col cols="3">
              <v-btn @click="loadNextPage" :loading="activoNextPage">
                Cargar más tracks
              </v-btn>
            </v-col>
          </v-row> -->
					<agregarRuta></agregarRuta>
					<ModalVencimiento
						v-if="dialogVencimiento"
						:dialog="dialogVencimiento"
						@closeDialogVecimiento="closeDialogVecimiento"
					></ModalVencimiento>
					<!-- <ModalLive
            v-if="dialogLive"
            :dialog="dialogLive"
            :img="
              idlive.live_online
                ? idlive.img_popup_online
                : idlive.img_popup_commingsoon
            "
            :online="idlive.live_online"
            @closeDialogLive="closeDialogLive"
          ></ModalLive> -->
					<NiuDialogModuloPremium
						:dialogModuloPremium="dialogModuloPremium"
						@CloseDialogModuloPremium="CloseDialogModuloPremium"
					></NiuDialogModuloPremium>
					<ModalVerMasInforamcion></ModalVerMasInforamcion>
				</v-col>
			</v-row>
			<!-- <v-row justify="center" no-gutters>
        <v-col cols="3">
          <ComingSoon />
        </v-col>
        <v-col cols="3">
          <ComingSoon />
        </v-col>
      </v-row> -->
		</v-col>
	</v-row>
</template>
<script>
import NewCarrusel from "../components/explora/newCarrusel.vue";
import NiuBanner from "../components/shared/NiuBanner.vue";
import CarrouselNIUFLEEX from "../components/explora/carruselEVA_NIUFLEEX.vue";
import CarrouselExplora from "../components/explora/NewCarruselEVA_LastViews.vue";
import CarrouselExploraMovil from "../components/explora/NewCarruselEVA_LastViews_Movil.vue";
import CarrouselModulos from "../components/explora/NewCarruselEVA_Modulos.vue";

// import NiuVideo from '../components/explora/videoMainVimeo.vue'
import NiuDialogVideo from "../components/explora/dialogVideo.vue";
import ComingSoon from "../components/explora/carruselEVA_comingsoon.vue";
import ModalVencimiento from "../components/explora/modalVencimiento.vue";
import ModalLive from "../components/explora/modalLive.vue";
import agregarRuta from "../components/explora/agregarMisRutas.vue";
import mensajeBajada from "../components/explora/mensajeBajada.vue";
import CarruselMasterClass from "../components/explora/NewCarruselEVA_MasterClass.vue";
import NiuDialogModuloPremium from "../components/modulo/dialogPremium.vue";
import ModalVerMasInforamcion from "../components/explora/NewCarruselEVA_ModalVerMasInfo.vue";
import { mapMutations } from "vuex";
export default {
	name: "Explora",
	components: {
		// Modulo,
		CarrouselNIUFLEEX,
		CarrouselExplora,
		CarrouselModulos,
		// NiuVideo,
		NiuDialogVideo,
		ComingSoon,
		ModalVencimiento,
		ModalLive,
		agregarRuta,
		mensajeBajada,
		CarruselMasterClass,
		NiuDialogModuloPremium,
		NiuBanner,
		NewCarrusel,
		CarrouselExploraMovil,
		ModalVerMasInforamcion,
	},
	methods: {
		...mapMutations(["SET_INFOTRACK"]),
		filterMasterClass() {
			this.showmasterclass = !this.showmasterclass;
			this.showfilter = [];
		},
		filterTracks(area) {
			this.showmasterclass = false;
			this.showfilter = this.showfilter.splice(1, 0);
			if (this.showfilter.length == 0) {
				this.showfilter.push(area);
			} else {
				if (this.showfilter.filter((ele) => ele == area).length > 0) {
					this.showfilter = this.showfilter.filter((ele) => ele != area);
				} else {
					this.showfilter.push(area);
				}
			}
		},
		scrolling(e) {
			// this.offsetTop = e.target.scrollingElement.scrollTop
			if (!this.stopNextPage) {
				let bottomOfWindow =
					Math.ceil(e.target.scrollingElement.scrollTop) +
						window.innerHeight ===
					document.documentElement.offsetHeight;

				if (bottomOfWindow && !this.activoNextPage) {
					this.loadNextPage();
				}
			}
		},
		async loadNextPage(e) {
			// eslint-disable-next-line no-console
			console.log(e);
			this.activoNextPage = true;
			this.nextpage++;
			let newsTracks = (
				await this.$http.get(`/Tracks/TracksNiufleexPremium/${this.nextpage}`)
			).data;
			this.activoNextPage = false;
			if (newsTracks) {
				let load = this.$storeNiu.getTextOrInt("loadCarruseles_premium");
				load.loadCarruseles = load.loadCarruseles.concat(newsTracks);
				this.$storeNiu.set("loadCarruseles_premium", load);
				this.loadCarruseles = this.loadCarruseles.concat(newsTracks);

				// console.log(this.loadCarruseles, newsTracks.length, this.stopNextPage)
				if (e != undefined && e.type == "click" && newsTracks.length == 0) {
					this.stopNextPage = true;
				}
			} else {
				this.stopNextPage = true;
			}
		},
		closeDialogLive() {
			this.dialogLive = false;
		},
		CloseDialogModuloPremium() {
			this.dialogModuloPremium = false;
		},
		OpenDialogModuloPremium() {
			// eslint-disable-next-line no-console
			console.log("paso");
			this.dialogModuloPremium = true;
		},
		closeDialogVecimiento() {
			this.dialogVencimiento = false;
			this.$store.commit("SET_VENCIMIENTOPASO", true);
		},
		resizeBanner() {
			if (document.querySelector("#banner")) {
				let aspecto = 13 / 39;

				// switch (this.$vuetify.breakpoint.name) {
				//   case 'xs':
				//     aspecto = 13 / 39
				//     break
				//   case 'sm':
				//     aspecto = 13 / 39
				//     break
				//   case 'md':
				//     aspecto = 13 / 39
				//     break
				//   case 'lg':
				//     aspecto = 13 / 39
				//     break
				//   case 'xl':
				//     aspecto = 13 / 39
				//     break
				// }

				this.altoBannerExplora =
					document.querySelector("#banner").offsetWidth * aspecto;

				this.altoVideo =
					(document.querySelector("#banner").offsetWidth * 9) / 16;
			}
		},
	},
	computed: {},
	data: () => ({
		btnLoadMore: false,
		nextpage: 1,
		stopNextPage: false,
		activoNextPage: false,
		dialogModuloPremium: false,
		model: null,
		dialog: false,
		idVideo: "ZGJizJsSF8A",
		muteYoutube: true,
		exploraCarruseles: [],
		loadCarruseles: [],
		altoBannerExplora: 500,
		altoVideo: 500,
		dialogVencimiento: false,
		dialogLive: false,
		isVideo: null,
		idlive: null,
		showfilter: ["emprendimientonegocio"],
		showmasterclass: false,
		filters: [],
	}),
	async created() {
		try {
			let lives = (await this.$http.post("/lives/liveId")).data;
			// eslint-disable-next-line no-console
			console.log(lives);
			if (lives.length > 0) {
				this.idlive = lives[0];
				this.dialogLive = this.idlive ? true : false;
			}
			// eslint-disable-next-line no-empty
		} catch (error) {}
		this.SET_INFOTRACK({
			trackTitle: "",
			trackCode: "",
			trackId: null,
			trackAreaconocimientoCode: "",
			trackProgreso: null,
			modulos: [],
		});
		this.loadCarruseles = (
			await this.$http.get("/Tracks/TracksNiufleexAll/false")
		).data;

		// let loadCarruseles = this.$storeNiu.getTextOrInt('loadCarruseles_premium')

		// eslint-disable-next-line no-console
		console.log("************loadCarruseles", this.loadCarruseles);
		window.loadcarruseles = this.loadCarruseles;
		// if (
		//   loadCarruseles == null ||
		//   loadCarruseles == {} ||
		//   new Date().getTime() - loadCarruseles.time >= 1800000
		// ) {
		//   this.loadCarruseles = (
		//     await this.$http.get('/Tracks/TracksNiufleexAll/true')
		//   ).data

		//   this.$storeNiu.set('loadCarruseles_premium', {
		//     time: new Date().getTime(),
		//     loadCarruseles: [...this.loadCarruseles]
		//   })
		// } else {
		//   this.loadCarruseles = this.$storeNiu.getTextOrInt(
		//     'loadCarruseles_premium'
		//   ).loadCarruseles
		// }
	},
	async mounted() {
		try {
			this.filters = (
				await this.$http.get("/areasconocimientos/universo")
			).data;

			// // eslint-disable-next-line no-console
			// console.group('newexplora')
			// // eslint-disable-next-line no-console
			// console.log(this.idlive, this.filters)
			// // eslint-disable-next-line no-console
			// console.groupEnd('newexplora')
			this.resizeBanner();
			// if (
			//   this.$storeNiu.getTextOrInt('restantes') < 5 &&
			//   !this.$store.state.vencimientoPaso
			// ) {
			//   this.dialogVencimiento = true
			// }
			// document.addEventListener('visibilitychange', function () {
			//   //eslint-disable-next-line no-console
			//   console.log(document.visibilityState)
			// })
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	},
};
</script>
<style lang="scss" scoped>
.niu-carousel-texto-modulo {
	background-color: rgba(0, 0, 0, 0.4);
	text-shadow: 1px 1px 1px #000;
	text-align: center;
	bottom: 0;
	height: 60px;
	padding: 4px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: bold;
}

@media (max-width: 600px) {
	.niu-col-height-video {
		height: 200px;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.niu-col-height-video {
		height: 340px;
	}
}

@media (min-width: 960px) and (max-width: 1264px) {
	.niu-col-height-video {
		height: 530px;
	}
}
@media (min-width: 1264px) and (max-width: 1904px) {
	.niu-col-height-video {
		height: 610px;
	}
}
@media (min-width: 1905px) {
	.niu-col-height-video {
		height: 900px;
	}
}
.v-icon.font-50 {
	font-size: 50px;
}
.v-icon.font-44 {
	font-size: 44px;
}
.niu-text-niufleex {
	color: #4d1552;
}
.txt-track-disponibles {
	color: black;
	font-size: 40px;
}
@media (max-width: 600px) and (min-width: 400px) {
	.txt-track-disponibles {
		color: black;
		font-size: 20px;
	}
}

@media (max-width: 400px) and (min-width: 300px) {
	.txt-track-disponibles {
		color: black;
		font-size: 20px;
	}
}
</style>
