var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"niu-width-track  mb-1",attrs:{"light":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticStyle:{"border-right":"1px solid #c3c3c3","height":"90%"}},[_c('div',{staticClass:"text-track-title",attrs:{"id":_vm.infoTrack.trackId}},[_c('label',{style:(("color:" + (_vm.$vuetify.theme.defaults.light.primary) + "; font-weight:bold"))},[_vm._v(_vm._s(((_vm.infoTrack.trackTitle) + " "))+" ")]),_c('v-avatar',{attrs:{"size":_vm.sizeLogoTrack}},[_c('v-img',{attrs:{"size":_vm.sizeLogoTrack,"src":_vm.infoTrack.isPremium === 0
                  ? require('../../assets/weblogo_n.png')
                  : require('../../assets/logo_premium_150x150.png')}})],1),(_vm.infoTrack.trackMobileFriendly === 1)?_c('v-avatar',{attrs:{"size":_vm.sizeLogoTrack,"color":"primary"}},[_c('v-img',{attrs:{"size":_vm.sizeLogoTrack,"src":require('../../assets/iconos/moviles.png')}})],1):_vm._e(),(_vm.infoTrack.isPersonalizadoNiufleex == 1)?_c('span',[_vm._v(" Personalizado ")]):_vm._e(),_c('p',[_c('span',{style:(("font-size:14px;font-weight:bold;color:" + (_vm.$vuetify.theme.defaults.light.primary)))},[_vm._v(" Curso ")]),_c('span',{style:(("color:" + (_vm.$vuetify.theme.defaults.light.secondary)))},[_vm._v(" | ")]),_c('span',{style:(("font-size:14px;font-weight:lighter;color:" + (_vm.$vuetify.theme.defaults.light.primary)))},[_vm._v(" Modalidad Track ")]),_c('v-btn',{staticClass:"pa-1 ma-0",staticStyle:{"color":"#FFF","font-size":"12px","text-transform":"capitalize"},attrs:{"color":_vm.$vuetify.theme.defaults.light.secondary},on:{"click":function($event){return _vm.ver_mas_informacion(_vm.infoTrack)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-play-circle-outline")]),(
                  _vm.$vuetify.breakpoint.name != 'xs' &&
                    _vm.$vuetify.breakpoint.name != 'sm'
                )?_c('span',[_vm._v("Ver más información")]):_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-plus")])],1)],1),_c('div',[(_vm.especialista.img)?_c('v-avatar',{staticClass:"ma-2"},[_c('v-img',{attrs:{"src":_vm.especialista.img ||
                    require('../../assets/especialistas/blanco.jpg')}})],1):_vm._e(),_c('label',{style:(("color:" + (_vm.$vuetify.theme.defaults.light.primary) + "; font-size: 14px;"))},[_vm._v(_vm._s(_vm.especialista.nickname))])],1)],1)])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-slide-group',{attrs:{"active-class":"success","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.modulos),function(modulo,index){return _c('v-slide-item',{key:("modulo_" + (_vm.$router.currentRoute.name) + "_" + (_vm.infoTrack.trackCode.substr(
              0,
              5
            )) + "_" + (modulo.agrupaModuloId) + "_slide" + index)},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{staticClass:"niu-card-main niu-background niu-text",class:{
                'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
                'niu-light': _vm.infoTrack.isPremium === 0 ? true : false
              },attrs:{"elevation":hover ? 15 : 1}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-img',{staticStyle:{"position":"relative !important"},attrs:{"aspect-ratio":17 / 9,"src":_vm.loadImage(modulo.moduloImg, modulo.comming_soon),"lazy-src":require("../../assets/client-loader.gif")}},[_c('div',{staticStyle:{"position":"absolute","z-index":"1","top":"46%","left":"46%"}},[_c('v-btn',{staticClass:"niu-play-modulo",staticStyle:{"text-shadow":"1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc, 0px -1px #ccc"},attrs:{"icon":""},on:{"click":function($event){return _vm.activeModulo(modulo)}}},[_c('v-icon',{attrs:{"size":"70px"}},[_vm._v(" mdi-play-circle ")])],1)],1),_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"0px"}},[_c('v-chip',{staticClass:"ma-2",class:_vm.colorFondoModuloClass(modulo)},[_vm._v(" Módulo "+_vm._s(modulo.moduloNumber)+" ")])],1)])],1)],1),_c('v-row',{staticClass:"pa-2 ma-0 ",attrs:{"align":"end"}},[_c('v-col',{staticClass:"ma-0 pa-0 niu-text-carrusel"},[_c('div',{staticStyle:{"position":"absolute","top":"-35px","right":"20px"}},[_c('v-avatar',{class:_vm.colorFondoModuloClass(modulo),attrs:{"size":"42"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(modulo.moduloNumber))])])],1),(!modulo.moduloHtml)?_c('div',{staticClass:"niu-carousel-texto-modulo"},[_vm._v(" "+_vm._s(modulo.moduloTitle)+" ")]):_c('span',{staticClass:"niu-carousel-texto-modulo",domProps:{"innerHTML":_vm._s(modulo.moduloTitle)}}),_c('p',{staticClass:"d-none d-sm-flex"},[_c('v-icon',{staticClass:"niu-text size-0-8-rem",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
                        {'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
                        'niu-light': _vm.infoTrack.isPremium === 0 ? true : false})},[_vm._v(" mdi-account ")]),_c('label',{staticClass:"niu-text"},[_c('strong',[_vm._v("Especialista: ")]),_vm._v(" "+_vm._s(modulo.moduloEspecialista))])],1)])],1),_c('v-sheet',{staticClass:"transparent",staticStyle:{"position":"absolute","bottom":"15px","width":"90%","left":"5%"},attrs:{"align":"center","justify":"end","light":""}},[_c('small',{staticClass:"niu-text-bold niu-text",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
                    {'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
                    'niu-light': _vm.infoTrack.isPremium === 0 ? true : false})},[_vm._v("Sesiones: "+_vm._s(modulo.moduloNroSesiones))]),_c('small',{staticClass:"niu-text-bold ml-4 niu-text",class:Object.assign({}, _vm.iconModuloClass(modulo, hover),
                    {'niu-dark': _vm.infoTrack.isPremium === 0 ? false : true,
                    'niu-light': _vm.infoTrack.isPremium === 0 ? true : false})},[_vm._v("Tiempo: "+_vm._s(modulo.moduloDuracion)+" minutos")])]),_c('div',{staticClass:"niu-barra",class:_vm.barraInferiorModuloCarrusel(modulo, hover)})],1)]}}],null,true)})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }