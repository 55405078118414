var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modulos.length > 0)?_c('v-sheet',{staticClass:"\n\t\t\tniu-transparent-explora-modulos niu-position-relative niu-width-track\n\t\t",staticStyle:{"background-color":"#dcdcdc !important","width":"100%"},attrs:{"elevation":"0"}},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"list-content"},_vm._l((_vm.modulos),function(modulo,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.areaMostrada[modulo.areaconocimientoCode]),expression:"areaMostrada[modulo.areaconocimientoCode]"}],key:("modulo_" + (_vm.$router.currentRoute.name) + "lastview_slide" + index),staticClass:"list-item"},[_c('v-card',{staticClass:"niu-card-main niu-background niu-text",class:{
							'niu-dark': modulo.isPremium === 0 ? false : true,
							'niu-light': modulo.isPremium === 0 ? true : false,
						}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-img',{attrs:{"aspect-ratio":17 / 9,"src":modulo.vimeo,"lazy-src":require("../../assets/client-loader.gif")}},[_c('div',{staticStyle:{"position":"absolute","top":"45%","right":"45%","z-index":"5"}},[_c('v-btn',{staticClass:"niu-play-modulo",staticStyle:{"text-shadow":"1px 0px #ccc, -1px 0px #ccc, 0px 1px #ccc,"},attrs:{"icon":""},on:{"click":function($event){return _vm.activeModulo(modulo)}}},[_c('v-icon',{attrs:{"size":"70px"}},[_vm._v(" mdi-play-circle ")])],1)],1)])],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"gray"},attrs:{"cols":"12"}},[_c('div',{style:(("background-color: " + (_vm.$vuetify.theme.defaults.light.secondary) + ";\n                                width: " + ((modulo.video_seconds /
																		60 /
																		modulo.video_duracion) *
																	100) + "%;\n                                height: 5px;"))})])],1),_c('v-row',{staticClass:"white--text pa-2 ma-0",staticStyle:{"font-size":"12px"},attrs:{"align":"end"}},[_c('v-col',[(!modulo.moduloHtml)?_c('span',{staticClass:"niu-carousel-texto-modulo",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(modulo.video_title)+" ")]):_c('span',{staticClass:"niu-carousel-texto-modulo niu-text",class:{
										'niu-dark': modulo.isPremium === 0 ? false : true,
										'niu-light': modulo.isPremium === 0 ? true : false,
									},domProps:{"innerHTML":_vm._s(modulo.video_title)}}),_c('p',[_c('label',{staticClass:"niu-text",class:{
											'niu-dark': modulo.isPremium === 0 ? false : true,
											'niu-light': modulo.isPremium === 0 ? true : false,
										},staticStyle:{"word-break":"break-word"}},[_c('strong',[_vm._v("Módulo "+_vm._s(modulo.moduloNumber))]),_c('span',{staticStyle:{"line-height":"18px"}},[_vm._v(" "+_vm._s(modulo.moduloTitle)+" ")])])]),_c('p',{staticStyle:{"height":"50px","padding":"15px 0 0 0"}},[_c('label',{staticClass:"niu-text mt-5",class:{
											'niu-dark': modulo.isPremium === 0 ? false : true,
											'niu-light': modulo.isPremium === 0 ? true : false,
										}},[_vm._v(" "+_vm._s(modulo.trackTitle))])])])],1)],1)],1)}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }